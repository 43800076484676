import React from 'react';
import { BRH1 } from '.';
import { I_BRH1 } from './BRH1';
import { useNavigate } from 'react-router-dom';

const HeaderDetail = ({ text }: I_BRH1) => {
	const navigate = useNavigate();

	return (
		<header className="dash-detail-title" onClick={() => navigate(-1)}>
			<BRH1 text={text} />
		</header>
	);
};

export default HeaderDetail;
