import React from 'react';
import { BRH1, Clue, DashTicket, Preloader } from '.';
import { GET_ANALYTICS } from '../../utils/GraphQLQueries';
import { useQuery } from '@apollo/client';
import DashTitle from './DashTitle';
import { Link } from 'react-router-dom';

const Dashboard = () => {
	const { loading, error, data } = useQuery(GET_ANALYTICS);

	if (loading) return <Preloader />;
	if (error) return <p>Error : {error.message}</p>;

	return (
		<section className="centroid">
			<BRH1 text={'Dashboard'} />
			<Clue
				text={`
					Track student progress, monitor course offerings, and manage payments all in one convenient dashboard.`}
			/>

			<header className="dash-tag-boxes">
				<DashTicket
					title={'Total Students'}
					value={data?.analytics?.totalStudents}
					icon={require('../../assets/girl-book-3d.png')}
				/>

				<DashTicket
					title={'Students Paid'}
					value={data?.analytics?.hasPaidSum}
					icon={require('../../assets/momo-3d.png')}
				/>

				<DashTicket
					title={'Programmes'}
					value={'6'}
					icon={require('../../assets/programmes-3d.png')}
				/>
			</header>

			{/* House Analytics */}
			<br />
			{/* <Clue text={`Analytics of total number of students in each HOUSE.`} /> */}
			<Clue text={`HOUSES.`} />
			<header
				className="dash-tag-boxes"
				style={{
					gridTemplateColumns: 'repeat(4, 1fr)',
				}}
			>
				<DashTicket
					title={'HOUSE 1: Akaboha'}
					value={data?.analytics?.house1}
					icon={require('../../assets/house1.png')}
				/>

				<DashTicket
					title={'HOUSE 2: Jimiru'}
					value={data?.analytics?.house2}
					icon={require('../../assets/house2.png')}
				/>

				<DashTicket
					title={'HOUSE 3: Nkrumah'}
					value={data?.analytics?.house3}
					icon={require('../../assets/house-sun.png')}
				/>

				<DashTicket
					title={'HOUSE 4: Valco'}
					value={data?.analytics?.house4}
					icon={require('../../assets/house4.png')}
				/>
			</header>

			{/* Programmes */}
			<br />
			{/* <Clue text={`Analytics of total number of students in each PROGRAMME.`} /> */}
			<Clue text={`PROGRAMMES.`} />
			<header className="dash-tag-boxes">
				<Link to={'/dashboard/programmes/general-science'}>
					<DashTicket
						title={'GENERAL SCIENCE'}
						value={data?.analytics?.scienceTotal}
						icon={require('../../assets/science-3d.png')}
					/>
				</Link>

				<Link to={'/dashboard/programmes/general-arts'}>
					<DashTicket
						title={'GENERAL ARTS'}
						value={data?.analytics?.generalArtsTotal}
						icon={require('../../assets/girl-book-3d.png')}
					/>
				</Link>

				<Link to={'/dashboard/programmes/agriculture'}>
					<DashTicket
						title={'AGRICULTURE'}
						value={data?.analytics?.agricTotal}
						icon={require('../../assets/agric-3d.png')}
					/>
				</Link>

				<Link to={'/dashboard/programmes/home-economics'}>
					<DashTicket
						title={'HOME ECONOMICS'}
						value={data?.analytics?.homeEconsCount}
						icon={require('../../assets/cook-3d.png')}
					/>
				</Link>

				<Link to={'/dashboard/programmes/visual-arts'}>
					<DashTicket
						title={'VISUAL ARTS'}
						value={data?.analytics?.visualArtsTotal}
						icon={require('../../assets/arts-3d.png')}
					/>
				</Link>

				<Link to={'/dashboard/programmes/business'}>
					<DashTicket
						title={'BUSINESS'}
						value={data?.analytics?.businessTotal}
						icon={require('../../assets/pay-money.png')}
					/>
				</Link>
			</header>

			{/* Resiential Status */}
			<br />
			{/* <Clue text={`Analytics of students RESIDENTIAL STATUS.`} /> */}
			<Clue text={`RESIDENTIAL STATUS.`} />
			<header
				className="dash-tag-boxes"
				style={{
					gridTemplateColumns: 'repeat(2, 1fr)',
				}}
			>
				<DashTicket
					title={'Day Students'}
					value={data?.analytics?.day}
					icon={require('../../assets/science-3d.png')}
				/>

				<DashTicket
					title={'Boarding Students'}
					value={data?.analytics?.boarding}
					icon={require('../../assets/girl-book-3d.png')}
				/>
			</header>

			<DashTitle />
		</section>
	);
};

export default Dashboard;
