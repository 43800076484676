import React, { useRef } from 'react';
import '../../css/Auth.css';
import { BRButton, Spacer } from '../Auth';
import { Clue } from '../Dashboard';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { FormatLongDate, TabTitle } from '../../utils/Functions';

interface Styles {
	refBox: React.CSSProperties;
	ref: React.CSSProperties;
	refLines: React.CSSProperties;
	letterTitle: React.CSSProperties;
}

const PrintStudent = () => {
	const studentPrintPaperRef = useRef<HTMLDivElement>(null);
	const { studentId } = useParams();

	const styles: Styles = {
		refBox: {
			width: '100%',
			maxWidth: '400px',
			display: 'flex',
			gap: '15px',
			flexDirection: 'column',
			marginBottom: '40px',
		},
		ref: {
			width: '100%',
			display: 'flex',
			gap: '10px',
		},
		refLines: {
			flex: 1,
			borderBottom: '1px dashed rgba(0, 0, 0, 0.5)',
		},
		letterTitle: {
			display: 'table',
			margin: 'auto',
			fontWeight: '700',
			fontSize: '16px',
			borderBottom: '2px solid rgba(0, 0, 0, 0.5)',
		},
	};

	//Integrations
	const GET_STUDENT = gql`
		query GetStudent($id: String!) {
			student(id: $id) {
				id
				fullName
				indexNumber
				enrolmentCode
				momoNumber
				hasPaid
				passportPhoto
				gender
				nationality
				programme
				class
				residentialStatus
				dateOfBirth
				placeOfBirth
				birthRegion
				aggregate
				JHSattended
				JHSdistrict
				JHStown
				JHSregion
				hometown
				hometownRegion
				permanentAddress
				religiousDenomination
				nameOfFather
				fatherOccupation
				fathersContact
				fathersEmail
				nameOfMother
				mothersOccupation
				mothersContact
				mothersEmail
				nameOfGuardian
				relationshipToApplicant
				guardianContact
				guardiansEmail
				residentialAddressOfGuardian
				otherTelephoneContact
				postalAddressOfGuardian
				interestOfApplicant
				schoolAwards
				positionHeldByApplicant
				acceptedAdmission
				house
				dateCreated
				dateModified
				modifiedBy
			}
		}
	`;

	const { data } = useQuery(GET_STUDENT, {
		variables: { id: studentId },
	});

	const student = data?.student;
	const pronoun = student?.gender?.toLowerCase() === 'male' ? 'him ' : 'her ';
	const posessivePronoun =
		student?.gender?.toLowerCase() === 'male' ? 'his ' : 'her ';
	let surname = student?.fullName.split(' ');
	surname = surname && surname[0];
	let names = student?.fullName?.split(' ');
	names?.shift();
	let otherNames = names && names?.join(' ');
	const academicYear = ` ${
		Number(new Date().getFullYear() - 1)
	}/${new Date().getFullYear()}`;

	let formattedName = student?.fullName
		?.split(' ')
		.map(
			(word: string) =>
				word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		)
		.join(' ');
	TabTitle(`Print ${formattedName}'s Data - Brilliance App`);

	// PRinting
	const handlePrint = () => {
		const printWindow = window.open(
			'',
			`${student?.fullName}-${student?.indexNumber}`,
			'height=600,width=800'
		);
		if (printWindow) {
			printWindow.document.write(`
        <html>
          <head>
            <title>${student?.fullName}-${student?.indexNumber}</title>
            <style>
              ${window.getComputedStyle(
								studentPrintPaperRef.current as Element
							)}
            </style>
          </head>
          <body>
            ${studentPrintPaperRef.current?.innerHTML}
          </body>
        </html>
      `);
			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		}
	};

	return (
		<section
			className="auth-box flex"
			style={{
				justifyContent: 'center',
				alignItems: 'unset',
			}}
		>
			<div>
				{/* Scroll Down */}
				<aside className="auth-paper">
					<h2 className="bri-h2">Scroll Down to Print</h2>
					<Clue text={'Scroll down to print your Form'} />
					<br />
				</aside>

				{/* PAPERS */}
				<br />
				<aside ref={studentPrintPaperRef} className="auth-paper print-paper">
					{/* PAGE 1 */}
					<section>
						{/* Letter Head */}
						<header style={{ width: '100%', overflowX: 'hidden' }}>
							<img
								src={require('../../assets/Mozano-Letter-Head.png')}
								alt="Letter Head"
								style={{ width: '100%', objectFit: 'contain' }}
							/>
						</header>
						{/* Ref */}
						<div style={styles.refBox}>
							<br />
							<div style={styles.ref}>
								<div>
									<i>Our Ref:</i>{' '}
								</div>
								<div style={styles.refLines} />
							</div>
							<div style={styles.ref}>
								<div>
									<i>Your Ref:</i>{' '}
								</div>
								<div style={styles.refLines} />
							</div>
						</div>
						{/* Title */}
						<div style={styles.letterTitle}>
							ADMISSION {academicYear} ACADEMIC YEAR
						</div>
						{/* Content */}
						<section>
							<br />
							<div>
								I am happy to inform you that on the basis of your ward’s
								performance at the B.E.C.E,{' '}
								{student?.gender?.toLowerCase() === 'male'
									? 'Mister '
									: 'Miss '}
								<b>{student?.fullName?.toUpperCase()}</b> has been offered
								admission into Mozano Senior High School as a{' '}
								<b>{student?.residentialStatus?.toUpperCase()}</b> student to
								pursue the Free S.H.S. Programme.
							</div>{' '}
							<br />
							<div style={{ textAlign: 'center' }}>
								<b>
									THE SCHOOL DOES NOT ENCOURAGE A CHANGE OF NAME AFTER ADMISSION
								</b>
							</div>
							<ol
								style={{ display: 'flex', flexDirection: 'column', gap: '7px' }}
							>
								<li>
									{student?.gender?.toLowerCase() === 'male' ? 'He ' : 'She '}
									is allocated to{' '}
									{student?.gender?.toLowerCase() === 'male'
										? 'Male '
										: 'Female '}
									<b>{student?.house?.toUpperCase()}</b>
								</li>
								<li>
									The programme offered {pronoun} is <b>{student?.programme}</b>
								</li>
								<li>
									The Class assigned {pronoun} is <b>{student?.class}</b>
									<br />
									Parents are to note that UNDER NO CIRCUMSTANCE WILL A STUDENT
									BE ALLOWED TO CHANGE TO ANOTHER PROGRAMME.
									<br />
								</li>
								<li>
									Fill the enclosed forms and return them without delay to:{' '}
									<br />
									<div style={{ paddingLeft: '40px' }}>
										<b>
											The Headmaster <br />
											Mozano Senior High School <br />
											P.O. BOX GE 20 <br />
											Gomoa Eshiem, Central Region
										</b>
									</div>
								</li>
								<li>
									You are required to attach a photocopy of your ward’s
									Admission Letter, B.E.C.E Results Slip and the Endorsed
									Placement Form to the completed form for submission to the
									Headmaster.
								</li>
								<li>
									Your ward should bring to school four (4) recent passport
									sized photographs of {`${pronoun.slice(0, -1)}self`} for{' '}
									{posessivePronoun} personal records.
								</li>
								<li>
									A thorough medical examination would be conducted in the
									school on each student a few days after arrival.
								</li>
								<li>
									<b>
										ALL NEWLY ADMITTED STUDENTS ARE TO REPORT ON MONDAY, 04TH DECEMBER, 2023.
									</b>
								</li>
								<p>
									Signed: <br />
									<img
										src={require('../../assets/Headmaster-Signature.png')}
										alt=""
										style={{ height: '80px' }}
										className="no-events"
									/>
									<br />
									<b>NICHOLAS WEBADUAH ADOEBI</b> <br />
									(HEADMASTER)
								</p>
							</ol>
						</section>
					</section>

					{/* PAGE 2 */}
					<section>
						{/* Letter Head */}
						<header style={{ width: '100%', overflow: 'hidden' }}>
							<img
								src={require('../../assets/Mozano-Letter-Head.png')}
								alt="Letter Head"
								style={{ width: '100%', objectFit: 'contain' }}
							/>
							<br />
							<br />
						</header>
						<aside
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<img
								src={student?.passportPhoto}
								alt={student?.fullName}
								style={{
									width: '198px',
									height: '198px',
									objectFit: 'cover',
									borderRadius: '5px',
								}}
							/>

							<div
								style={{
									width: '350px',
									padding: '15px',
									border: '1px solid rgba(0, 0, 0, 0.5)',
									display: 'flex',
									flexDirection: 'column',
									gap: '10px',
								}}
							>
								<div style={{ textAlign: 'center' }}>(FOR OFFICE USE ONLY)</div>
								<div style={styles.ref}>
									CSSPS ID NO:{' '}
									<div style={styles.refLines}>
										<b>{student?.enrolmentCode}</b>
									</div>
								</div>
								<div style={styles.ref}>
									FORM:{' '}
									<div style={styles.refLines}>
										<b>{student?.class?.toUpperCase()}</b>
									</div>
								</div>
								<div style={styles.ref}>
									HOUSE:{' '}
									<div style={styles.refLines}>
										<b>{student?.house}</b>
									</div>
								</div>
								<div style={styles.ref}>
									BOARDING/DAY:{' '}
									<div style={styles.refLines}>
										<b>{student?.residentialStatus?.toUpperCase()}</b>{' '}
									</div>
								</div>
								<div style={styles.ref}>
									DATE OF ADMISSION:{' '}
									<div style={styles.refLines}>
										<b>04TH DECEMBER, 2023</b>
									</div>
								</div>
							</div>
						</aside>
						{/* Content */}
						<section>
							<br />
							<ol
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '5px',
								}}
							>
								<li>
									Surname of student: <b>{surname?.toUpperCase()}</b>
								</li>
								<li>
									Other names of student (Full):{' '}
									<b>{otherNames?.toUpperCase()}</b>
								</li>
								<li>
									Date of Birth: ....<b>{student?.dateOfBirth}</b>.... Place of
									Birth: <b>{student?.placeOfBirth?.toUpperCase()}</b>....
									Region: <b>{student?.birthRegion?.toUpperCase()}</b>
								</li>
								<li>
									Hometown: <b>{student?.hometown?.toUpperCase()}</b>
								</li>
								<li>
									Nationality: <b>{student?.nationality?.toUpperCase()}</b>
								</li>
								<li>
									Religious Denomination:{' '}
									<b>{student?.religiousDenomination?.toUpperCase()}</b>
								</li>
								<li>
									<div style={styles.ref}>
										<div>Last school attended (with dates):</div>
										<b>{student?.JHSattended?.toUpperCase()}</b>
									</div>
								</li>
								<li>
									<div style={styles.ref}>
										<div>Class/Form of last school attended:</div>
										<b>J.H.S. 3</b>
									</div>
								</li>
								{/* <li>
									<div style={styles.ref}>
										<div>Date of Last Attendance at last school:</div>
										<div style={styles.refLines} />
									</div>
								</li> */}
								<li>
									<div style={styles.ref}>
										<div>Boarder/Day Scholar on Admission into MOSEC:</div>
										<b>{student?.residentialStatus?.toUpperCase()}</b>
									</div>
								</li>
								<li>
									Name of Parent/Guardian:{' '}
									<b>{student?.nameOfGuardian?.toUpperCase()}</b>
								</li>
								<li>
									Relationship to Applicant:{' '}
									<b>{student?.relationshipToApplicant?.toUpperCase()}</b>
								</li>
								{/* <li>
									<div style={styles.ref}>
										<div>Full Postal Address of Parent/Guardian:</div>
										{student?.postalAddressOfGuardian ? (
											student?.postalAddressOfGuardian?.toUpperCase()
										) : (
											<div style={styles.refLines} />
										)}
									</div>
								</li> */}
								<li>
									Gurdian's Contact: <b>{student?.guardianContact}</b> .....
									Guardian's Email: <b>{student?.guardiansEmail}</b>
								</li>
								<li>
									<div style={styles.ref}>
										<div>Full Residential Address of Parent/Guardian:</div>
										<b>
											{student?.residentialAddressOfGuardian?.toUpperCase()}
										</b>
									</div>
								</li>
								<li>
									Name of Father: <b>{student?.nameOfFather?.toUpperCase()}</b>
								</li>
								<li>
									Father's Contact: <b>{student?.fathersContact}</b> .....
									Father's Email: <b>{student?.fathersEmail}</b>
								</li>
								<li>
									Name of Mother: <b>{student?.nameOfMother?.toUpperCase()}</b>
								</li>
								<li>
									Mother's Contact: <b>{student?.mothersContact}</b> .....
									Mother's Email: <b>{student?.mothersEmail}</b>
								</li>
							</ol>
							<div style={styles.ref}>
								<div>Signature of Student:</div>
								<div style={styles.refLines} />
								<div>Date: </div>
								<b>
									{student?.dateCreated &&
										FormatLongDate(student?.dateCreated)?.toUpperCase()}
								</b>
							</div>
							<br />
							<br />
							<p>
								<br />
							</p>
						</section>
					</section>

					{/* PAGE 3 */}
					<section>
						{/* Letter Head */}
						<header style={{ width: '100%', overflowX: 'hidden' }}>
							<img
								src={require('../../assets/Mozano-Letter-Head.png')}
								alt="Letter Head"
								style={{ width: '100%', objectFit: 'contain' }}
							/>
							<br />
							<br />
						</header>
						{/* Title */}
						<div style={styles.letterTitle}>
							ADMISSION TO S.H.S. 1 - {academicYear} ACADEMIC YEAR
						</div>
						<div style={styles.letterTitle}>ACCEPTANCE/DECLARATION FORM</div>

						<p>
							STUDENT’S ADMISSION NUMBER (CSSPS ID):{' '}
							<b>{student?.enrolmentCode}</b>
							<br />I hereby <b>accept admission</b> to SHS 1 at Mozano Senior
							High School as a{' '}
							<b>{student?.rresidentialStatus?.toUpperCase()}</b> student to do{' '}
							<b>{student?.programme}</b> in the {academicYear} academic year.
							<br />I agree to abide by all rules and regulations and I promise
							to maintain a high standard of academic work and conduct.
						</p>
						<p>
							Name of Student: <b>{student?.fullName?.toUpperCase()}</b>
							<div style={styles.ref}>
								<div>Signature of Student:</div>
								<div style={styles.refLines} />
								<div>Date: </div>
								<div style={styles.refLines} />
							</div>
						</p>
						<p>
							<br />
							<b>DECLARATION OF PARENT/GUARDIAN</b>
							<br />
							<br />I hereby declare that the above-named applicant is my
							{student?.gender?.toLowerCase() === 'male' ? ' son' : ' daughter'}
							, and I am responsible for {posessivePronoun} education. I fully
							accept that
							{student?.gender?.toLowerCase() === 'male' ? ' he' : ' she'}{' '}
							should abide by the rules and regulations of the school as
							promised by {posessivePronoun.slice(0, -1)}. If{' '}
							{student?.gender?.toLowerCase() === 'male' ? 'he' : 'she'} fails
							in any of these promises, the law of the school should take its
							course. I also promise that my ward will be of good behaviour
							throughout the full duration of {posessivePronoun.slice(0, -1)}{' '}
							course of study in the school and{' '}
							<b>
								will accept the decision of the Management in all matters of
								discipline.
							</b>
							<br />
						</p>
						<p>
							<b>PARENT/GUARDIAN’S FULL NAME AND ADDRESS:</b>
							<br />
							<br />
							<div style={styles.refLines} />
						</p>
						<p>
							<b>PARENT/GUARDIAN’S SIGNATURE:</b>
							<br />
							<br />
							<div style={styles.refLines} />
						</p>
						<p style={styles.ref}>
							<div>DATE:</div>
							<div style={styles.refLines} />
							<br />
						</p>
						<p style={styles.ref}>
							<div>CONTACT 1:</div>
							<div style={styles.refLines} />
							<br />
						</p>
						<p style={styles.ref}>
							<div>CONTACT 2:</div>
							<div style={styles.refLines} />
						</p>
					</section>
				</aside>

				<br />
				{/* Print Card */}
				<aside className="auth-paper">
					<h2 className="bri-h2">Successfully Submitted Admission Data</h2>
					<Clue text={'Click the button be low to print your form'} />
					<br />
					<BRButton text={'Print Your Form'} onClick={handlePrint} />
				</aside>
				<Spacer />
			</div>
			<br />
			<br />
		</section>
	);
};

export default PrintStudent;
