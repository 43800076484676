import React from 'react';
import { TabTitle } from '../../utils/Functions';

const DashTitle = () => {
	TabTitle('Dashboard - Brilliance App');

	return <></>;
};

export default DashTitle;
