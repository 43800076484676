import '../../css/Dashboard.css';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { gql, useQuery } from '@apollo/client';
import { InfoTab, Preloader } from '.';
import {
	editId,
	selectStudentInfoExpanded,
	selectStudentInfoOpen,
	toggleStudentInfoExpanded,
	toggleStudentInfoOpen,
	updateEditID,
} from '../../redux-context/slices/appSlice';
import CollapseIcon from '../../assets/collapse.png';
import ExpandIcon from '../../assets/expand.png';
import { BRButton } from '../Auth';
import { FormatLongDate, TabTitle, randNum } from '../../utils/Functions';

const StudentInfo = () => {
	const dispatch = useDispatch();
	const previewId = useSelector(editId);
	const studentInfoOpen = useSelector(selectStudentInfoOpen);
	const studentInfoExpanded = useSelector(selectStudentInfoExpanded);

	const toolTipId = [`tooltip-${randNum()}`, `tooltip-${randNum()}`];

	const handleClose = () => {
		studentInfoExpanded && dispatch(toggleStudentInfoExpanded());
		studentInfoOpen && dispatch(toggleStudentInfoOpen());
		studentInfoOpen && dispatch(updateEditID(''));
	};

	//Integrations
	const GET_STUDENT = gql`
		query GetStudent($id: String!) {
			student(id: $id) {
				id
				fullName
				indexNumber
				enrolmentCode
				momoNumber
				hasPaid
				passportPhoto
				gender
				nationality
				programme
				class
				residentialStatus
				dateOfBirth
				placeOfBirth
				birthRegion
				aggregate
				JHSattended
				JHSdistrict
				JHStown
				JHSregion
				hometown
				hometownRegion
				permanentAddress
				religiousDenomination
				nameOfFather
				fatherOccupation
				fathersContact
				fathersEmail
				nameOfMother
				mothersOccupation
				mothersContact
				mothersEmail
				nameOfGuardian
				relationshipToApplicant
				guardianContact
				guardiansEmail
				residentialAddressOfGuardian
				otherTelephoneContact
				postalAddressOfGuardian
				interestOfApplicant
				schoolAwards
				positionHeldByApplicant
				acceptedAdmission
				house
				dateCreated
				dateModified
				modifiedBy
			}
		}
	`;

	const { loading, error, data } = useQuery(GET_STUDENT, {
		variables: { id: previewId },
	});

	const student = data?.student;
	student?.fullName !== undefined &&
		TabTitle(`${student?.fullName}'s Information - Brilliance App`);

	if (error) <p>Error: {error.message}</p>;

	const handlePrintStudentInformation = () => {
		const url = `/print/${previewId}`;
		window.open(url, '_blank');
	};

	return (
		<aside
			className={`student-info${studentInfoExpanded ? ' info-expanded' : ''}`}
			style={{
				// visibility: studentInfoOpen ? 'visible' : 'hidden',
				// display: studentInfoOpen ? 'block' : 'none',
				right: studentInfoOpen ? 0 : '-500px',
			}}
		>
			{!loading ? (
				<>
					<header
						className="close-exp flex"
						style={{
							display: 'flex',
							gap: '15px',
							justifyContent: 'space-between',
							margin: '10px',
							transform: 'translateY(-10px)',
						}}
					>
						<Tooltip anchorId={toolTipId[0]} />
						<Tooltip anchorId={toolTipId[1]} />
						{/* expand */}
						<div
							id={toolTipId[0]}
							className="expand"
							onClick={() => dispatch(toggleStudentInfoExpanded())}
							style={{
								backgroundImage: studentInfoExpanded
									? `url(${CollapseIcon})`
									: `url(${ExpandIcon})`,
							}}
							data-tooltip-content={
								studentInfoExpanded ? 'Collapse View' : 'Expand View'
							}
							data-tooltip-place="top"
						/>

						{/* Edit */}
						{/* <div className="btn-edit">Edit Student Info</div> */}

						{/* close */}
						<div
							id={toolTipId[1]}
							className="close"
							onClick={handleClose}
							data-tooltip-content="Close Information"
							data-tooltip-place="top"
						/>
					</header>

					<header
						className="student-pic"
						style={{
							width: '200px',
							height: '200px',
							borderRadius: '5px',
							overflow: 'hidden',
							margin: 'auto',
							marginBottom: '15px',
							backgroundColor: 'rgba(0, 0, 0, 0.1)',
							backgroundImage: 'url(../../assets/user.png")',
							backgroundSize: '70px',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							border: '1px solid rgba(0, 0, 0, 0.1)',
						}}
					>
						{student?.passportPhoto && (
							<img
								src={student?.passportPhoto}
								alt=""
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
								}}
							/>
						)}
					</header>

					<section
						className="student-info-content"
						style={{
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
							gap: '10px',
						}}
					>
						<InfoTab label={'Full name'} value={student?.fullName} />
						<InfoTab label={'Index Number'} value={student?.indexNumber} />
						<InfoTab label={'Enrollment Code'} value={student?.enrolmentCode} />
						<InfoTab label={'Aggregate'} value={student?.aggregate} />
						<InfoTab label={'Programme'} value={student?.programme} />
						<InfoTab label={'Class'} value={student?.class} />
						<InfoTab label={'Gender'} value={student?.gender} />
						<InfoTab
							label={'Residential Status'}
							value={student?.residentialStatus}
						/>
						<InfoTab label={'House'} value={student?.house} />
						<InfoTab label={'Date of Birth'} value={student?.dateOfBirth} />
						<InfoTab label={'Nationality'} value={student?.nationality} />
						<InfoTab label={'Place of Birth'} value={student?.placeOfBirth} />
						<InfoTab label={'Birth Region'} value={student?.birthRegion} />
						<InfoTab label={'JHS Attended'} value={student?.JHSattended} />
						<InfoTab label={'JHS Region'} value={student?.JHSregion} />
						<InfoTab label={'JHS District'} value={student?.JHSdistrict} />
						<InfoTab label={'JHS Town'} value={student?.JHStown} />
						<InfoTab
							label={'Has Paid'}
							value={student?.hasPaid ? 'Yes' : 'Not Yet'}
						/>

						{/* Demographic Information */}
						<div
							className="student-info-title"
							style={{
								gridColumn: 'span 2',
								fontSize: '12px',
								textAlign: 'center',
								margin: '10px 0',
								fontWeight: 600,
								fontFamily: 'Open Sans, sans-serif',
								position: 'relative',
							}}
						>
							Demographic Information
						</div>
						<InfoTab label={'Hometown'} value={student?.hometown} />
						<InfoTab
							label={'Hometown Region'}
							value={student?.hometownRegion}
						/>
						<InfoTab
							label={'Permanent Address'}
							value={student?.permanentAddress}
						/>
						<InfoTab
							label={'Religious Denomination'}
							value={student?.religiousDenomination}
						/>
						<InfoTab label={'Name of Father'} value={student?.nameOfFather} />
						<InfoTab
							label={"Father's Occupation"}
							value={student?.fatherOccupation}
						/>
						<InfoTab
							label={"Father's Contact"}
							value={student?.fathersContact}
						/>
						<InfoTab label={"Father's Email"} value={student?.fathersEmail} />
						<InfoTab label={'Name of Mother'} value={student?.nameOfMother} />
						<InfoTab
							label={"Mother's Occupation"}
							value={student?.mothersOccupation}
						/>
						<InfoTab
							label={"Mother's Contact"}
							value={student?.mothersContact}
						/>
						<InfoTab label={"Mother's Email"} value={student?.mothersEmail} />
						{/* Guardian & Interests Information */}
						<div
							className="student-info-title"
							style={{
								gridColumn: 'span 2',
								fontSize: '12px',
								textAlign: 'center',
								margin: '10px 0',
								fontWeight: 600,
								fontFamily: 'Open Sans, sans-serif',
								position: 'relative',
							}}
						>
							Guardian & Interests Information
						</div>
						<InfoTab
							label={'Name of Guardian'}
							value={student?.nameOfGuardian}
						/>
						<InfoTab
							label={'Relationship to the Applicant'}
							value={student?.relationshipToApplicant}
						/>
						<InfoTab
							label={'Contact of Guardian'}
							value={student?.guardianContact}
						/>
						<InfoTab
							label={"Guardian's Email"}
							value={student?.guardiansEmail}
						/>
						<InfoTab
							label={'Residential Address of Guardian'}
							value={student?.residentialAddressOfGuardian}
						/>
						<InfoTab
							label={'Postal Address of Guardian'}
							value={student?.postalAddressOfGuardian}
						/>
						<InfoTab
							label={'Other Telephone Contact'}
							value={student?.otherTelephoneContact}
						/>
						<InfoTab label={'School Awards'} value={student?.schoolAwards} />
						<InfoTab
							label={'Position(s) Held By Applicant'}
							value={student?.positionHeldByApplicant}
						/>
						<InfoTab
							label={'Interests of Applicant'}
							value={student?.interestOfApplicant
								?.map((interest: string, index: number) =>
									index !== student.interestOfApplicant.length - 1
										? `${interest}, `
										: `${interest}`
								)
								.join('')}
						/>
						<InfoTab label={'Applicant Agreed to Follow Rules'} value={'Yes'} />
						<InfoTab
							label={'Date Applied'}
							value={
								student?.dateCreated && FormatLongDate(student?.dateCreated)
							}
						/>
					</section>

					<footer className="footer-print">
						<BRButton
							text={'Print Student Information'}
							onClick={handlePrintStudentInformation}
						/>
					</footer>
				</>
			) : (
				<Preloader />
			)}
		</aside>
	);
};

export default StudentInfo;
