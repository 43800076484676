import React, { useEffect, useState } from 'react';
import { PaystackButton } from 'react-paystack';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectStudent,
	updateStudent,
} from '../../redux-context/slices/studentSlice';
import { useNavigate } from 'react-router-dom';

const GET_STUDENT = gql`
	query GetStudent($indexNumber: String!) {
		studentByIndex(indexNumber: $indexNumber) {
			id
			fullName
			indexNumber
			hasPaid
		}
	}
`;

const UPDATE_STUDENT = gql`
	mutation UpdateStudent($indexNumber: String!, $hasPaid: Boolean!) {
		editStudent(indexNumber: $indexNumber, hasPaid: $hasPaid) {
			id
			indexNumber
			fullName
			enrolmentCode
			hasPaid
		}
	}
`;

const PayStackBtn = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const student = useSelector(selectStudent);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [paystackReference, setPaystackReference] = useState('');

	// your Paystack configuration goes here
	const paystackConfig = {
		reference: new Date().getTime(),
		email: `${student?.indexNumber}@mozanoshs.org`,
		publicKey: 'pk_live_eeeeb58acd9f351718c0270617ff9ab1b3a68fa1',
		currency: 'GHS',
		amount: window.location.hostname === 'localhost' ? 100 : 5000,
	};

	// handle successful payment
	const handleSuccess = (reference: any) => {
		editStudent({
			variables: {
				indexNumber: student.indexNumber.toString(),
				hasPaid: true,
			},
		})
			.then((res: any) => {
				dispatch(updateStudent({ hasPaid: true }));
				navigate('/form');
			})
			.catch((err: any) => {
				console.log('ERR: ', err);
			});

		console.log('Payment successful!', reference);
	};

	const [editStudent] = useMutation(UPDATE_STUDENT);
	const { data } = useQuery(GET_STUDENT, {
		variables: { indexNumber: student?.indexNumber },
		skip: student?.indexNumber?.length < 10,
	});

	useEffect(() => {
		if (data?.studentByIndex?.hasPaid) {
			navigate('/form');
		}
	}, [data, navigate]);

	// handle failed payment
	const handleFailure = (reference: any) => {
		console.log('Payment failed!', reference);
	};

	return (
		<div>
			<PaystackButton
				text="Make Payment"
				//@ts-ignore
				onSuccess={handleSuccess}
				onFailure={handleFailure}
				disabled={false}
				embed={true}
				//@ts-ignore
				reference={paystackConfig.reference}
				email={paystackConfig.email}
				amount={paystackConfig.amount}
				publicKey={paystackConfig.publicKey}
				//@ts-ignore
				currency={paystackConfig.currency}
				tag="button"
				metadata={{
					custom_fields: [
						{
							display_name: 'Paid for',
							variable_name: 'paid_for',
							value: 'Admission System Charges',
						},
					],
				}}
				className="paystack-button"
				callback={(response: any) => {
					setPaystackReference(response.reference);
				}}
				onClose={() => {
					console.log('Payment closed!');
				}}
			/>
		</div>
	);
};

export default PayStackBtn;
