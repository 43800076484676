import React from 'react';
import DashCourse from './DashCourse';
import { BRH1, Clue, Preloader } from '.';
import { useQuery } from '@apollo/client';
import { GET_ANALYTICS } from '../../utils/GraphQLQueries';
import { TabTitle } from '../../utils/Functions';

const Programmes = () => {
	const { loading, error, data } = useQuery(GET_ANALYTICS);
	if (loading) return <Preloader />;
	if (error) return <p>Error : {error.message}</p>;

	TabTitle('Programmes - Brilliance App');

	return (
		<section className="centroid">
			<BRH1 text={'Programmes'} />
			<Clue text={`Click programme to view more of program.`} />

			<section className="dash-programes">
				<DashCourse
					title={'GENERAL SCIENCE'}
					total={data?.analytics?.scienceTotal}
					icon={require('../../assets/science-3d.png')}
					to={'/dashboard/programmes/general-science'}
				/>

				<DashCourse
					title={'GENERAL ARTS'}
					total={data?.analytics?.generalArtsTotal}
					icon={require('../../assets/girl-book-3d.png')}
					to={'/dashboard/programmes/general-arts'}
				/>

				<DashCourse
					title={'AGRICULTURE'}
					total={data?.analytics?.agricTotal}
					icon={require('../../assets/agric-3d.png')}
					to={'/dashboard/programmes/agriculture'}
				/>

				<DashCourse
					title={'HOME ECONOMICS'}
					total={data?.analytics?.homeEconsCount}
					icon={require('../../assets/cook-3d.png')}
					to={'/dashboard/programmes/home-economics'}
				/>

				<DashCourse
					title={'VISUAL ARTS'}
					total={data?.analytics?.visualArtsTotal}
					icon={require('../../assets/arts-3d.png')}
					to={'/dashboard/programmes/visual-arts'}
				/>

				<DashCourse
					title={'BUSINESS'}
					total={data?.analytics?.businessTotal}
					icon={require('../../assets/pay-money.png')}
					to={'/dashboard/programmes/business'}
				/>
			</section>
		</section>
	);
};

export default Programmes;
