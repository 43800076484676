import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectIsMobileMenuOpen,
	toggleMobileMenuOpen,
} from '../../redux-context/slices/appSlice';

const MobileNav = () => {
	const isMenuOpen = useSelector(selectIsMobileMenuOpen);
	const dispatch = useDispatch();

	return (
		<>
			<nav className="mobile-nav flex">
				<img
					src={
						isMenuOpen
							? require('../../assets/cancel.png')
							: require('../../assets/hamburger.png')
					}
					onClick={() => dispatch(toggleMobileMenuOpen())}
					alt=" "
					className="mobile-burger"
				/>
				<img
					src={require('../../assets/brilliance-app.png')}
					className="mobile-logo"
					alt=" "
				/>
				<div></div>
			</nav>
		</>
	);
};

export default MobileNav;
