import React, { useState } from 'react';

interface IBRTextField {
	type: 'text' | 'number' | 'email' | 'password' | 'date';
	isDisabled?: boolean;
	error?: string;
	placeholder: string;
	onChange: (e: any) => void | (() => void) | any;
	icon?: string;
	value?: string | number;
	min?: number;
	max?: number;
	onKeyPress?: (e: any) => void | (() => void);
}

/**
 * Text Field Component can hold numbers and strings
 * @param {string} type - could be text or number
 * @param {string} placeholder - text to be used as placeholder
 * @param {() => void} onChange - the function to be run on change
 * @param {string} icon - the icon to be displayed on the right
 * @returns Text Field Component
 */
const BRTextField = ({
	type = 'text',
	isDisabled,
	error,
	placeholder,
	onChange,
	icon,
	value,
	min,
	max,
	onKeyPress,
}: IBRTextField) => {
	if (type === 'number' && window.screen.width > 999) {
		type = 'text';
	}

	const [hidePassword, setHidePassword] = useState(true);

	return (
		<div
			className={`br-field ${isDisabled ? 'no-events' : ''}`}
			style={{
				opacity: isDisabled ? 0.7 : 1,
			}}
		>
			<div className="tsa-text-field">
				<input
					type={
						type !== 'password'
							? type
							: type === 'password' && hidePassword
							? type
							: 'text'
					}
					placeholder={''}
					onChange={onChange}
					onKeyDown={onKeyPress}
					defaultValue={value}
					minLength={min}
					maxLength={max}
					style={{
						borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.3)',
					}}
				/>
				<span
					style={{
						color: error ? 'red' : '#000',
					}}
				>
					{placeholder}
				</span>
				{icon && <img src={icon} alt="" className="tsa-tf-icon" />}
				{type === 'password' && (
					<div
						className={`br-pass-view ${!hidePassword && 'br-pass-hide'}`}
						onClick={() => setHidePassword(!hidePassword)}
					/>
				)}
			</div>
			{error && <div className="br-error">{error}</div>}
		</div>
	);
};

export default BRTextField;
