import React from 'react';

interface ISpacer {
	size?: number;
}

/**
 * Applies a specific spacing in height for  page contents
 * @param size - NUMBER: the height of the space
 * @returns Vertical Spacing Element
 */
const Spacer = ({ size = 50 }: ISpacer) => {
	return (
		<div
			style={{
				width: '100%',
				height: `${size}px`,
				transition: '300ms ease',
			}}
		></div>
	);
};

export default Spacer;
