import React, { useState } from 'react';
import { BRButton, BRTextField } from '.';
import Spacer from './Spacer';
import { NumbersOnly, TabTitle } from '../../utils/Functions';
import { useNavigate } from 'react-router-dom';
import {
	selectStudent,
	updateStudent,
} from '../../redux-context/slices/studentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';

const StudentMomo = () => {
	TabTitle('Mobile Money • Brilliance App');
	const navigate = useNavigate();
	const MTNPrefixes = ['024', '054', '055', '059', '025'];
	const [error, setError] = useState('');
	const [momoNum, setMomoNum] = useState('');
	const [isPending, setIsPending] = useState(false);
	const dispatch = useDispatch();
	const student = useSelector(selectStudent);

	const checkForPrefix = (str: string) => {
		return MTNPrefixes.some((prefix) => str.startsWith(prefix));
	};

	/** Validation for Index Number */
	const valitateMomoNum = (e: any) => {
		NumbersOnly(e);

		const num = e.target.value;
		if (num.length > 2 && !checkForPrefix(num)) {
			setError('Only Ghanaian MTN Numbers are supported');
		} else {
			setError('');
		}
	};

	const UPDATE_STUDENT = gql`
		mutation UpdateStudent($indexNumber: String!, $momoNumber: String!) {
			editStudent(indexNumber: $indexNumber, momoNumber: $momoNumber) {
				id
				indexNumber
				fullName
				momoNumber
			}
		}
	`;

	/** Submit function of momo number form */
	const handleMomoNumSubmit = () => {
		if (momoNum.length < 10) {
			setError('Required MTN Momo Number is 10 characters long');
			return;
		}

		setIsPending(true);

		editStudent({
			variables: {
				indexNumber: student.indexNumber.toString(),
				momoNumber: momoNum,
			},
		})
			.then((res) => {
				dispatch(updateStudent({ momoNumber: momoNum }));
				setTimeout(() => {
					navigate('/pay-confirm');
					setIsPending(false);
				}, 1000);
			})
			.catch((err) => {
				console.log('ERR: ', err);
				setIsPending(false);
			});
	};

	const [editStudent] = useMutation(UPDATE_STUDENT);

	return (
		<div className="auth-box flex">
			<aside className="auth-paper">
				<img
					className="auth-app-logo"
					src={require('../../assets/mtn.png')}
					alt=""
					title="Brilliance App"
				/>

				<h2 className="bri-h2">MTN Mobile Money Number</h2>

				<Spacer size={10} />
				<div className="auth-form flex">
					<BRTextField
						type={'number'}
						placeholder={'MTN Mobile Money Number'}
						onChange={(e) => setMomoNum(e.target.value)}
						min={10}
						max={10}
						error={error}
						value={0}
						onKeyPress={valitateMomoNum}
					/>

					<Spacer size={5} />

					<BRButton
						text={'Continue'}
						onClick={handleMomoNumSubmit}
						isProcessing={isPending}
					/>
				</div>
			</aside>
		</div>
	);
};

export default StudentMomo;
