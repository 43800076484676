import React from 'react';

const Preloader = () => {
	return (
		<div className="preloader-box">
			<div className="preloader" />
			Loading...
		</div>
	);
};

export default Preloader;
