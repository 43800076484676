import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
	AuthEnrolmentCode,
	AuthIndexNumber,
	AuthLogin,
	PayUI,
	StudentMomo,
} from './components/Auth';
import './css/App.css';
import './css/Auth.css';
import { DashboardPage, StudentFormPage } from './pages';
import {
	selectIsMobileMenuOpen,
	selectStudentInfoExpanded,
	selectStudentInfoOpen,
	toggleMobileMenuOpen,
	toggleStudentInfoExpanded,
	toggleStudentInfoOpen,
	updateEditID,
} from './redux-context/slices/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import 'react-tooltip/dist/react-tooltip.css';
import { PrintStudent } from './components/StudentForm';
import { readFromLocalStorage } from './utils/Functions';

const App = () => {
	const dispatch = useDispatch();
	const studentInfoOpen = useSelector(selectStudentInfoOpen);
	const studentInfoExpanded = useSelector(selectStudentInfoExpanded);
	const isMenuOpen = useSelector(selectIsMobileMenuOpen);

	const handleOverlayToggle = () => {
		studentInfoExpanded && dispatch(toggleStudentInfoExpanded());
		studentInfoOpen && dispatch(toggleStudentInfoOpen());
		studentInfoOpen && dispatch(updateEditID(''));
		isMenuOpen && dispatch(toggleMobileMenuOpen());
	};

	/** Prevent Right CLick on Production Server */
	if (process.env.NODE_ENV !== 'development') {
		window.addEventListener('contextmenu', (event) => {
			event.preventDefault();
		});
	}

	const hasLoggedIn = JSON.parse(readFromLocalStorage('useWrite')!);
	useEffect(() => {
		if (!hasLoggedIn) {
			handleOverlayToggle();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasLoggedIn]);

	return (
		<>
			<div
				className="overlay"
				onClick={handleOverlayToggle}
				style={{
					opacity: studentInfoOpen || isMenuOpen ? 1 : 0,
					visibility: studentInfoOpen || isMenuOpen ? 'visible' : 'hidden',
				}}
			/>
			<Routes>
				<Route path="/" index element={<AuthIndexNumber />} />
				<Route path="enroll-code" element={<AuthEnrolmentCode />} />
				<Route path="momo" element={<StudentMomo />} />
				<Route path="pay-confirm" element={<PayUI />} />
				<Route path="auth" element={<AuthLogin />} />
				<Route path="form/*" element={<StudentFormPage />} />
				<Route path="print/:studentId" element={<PrintStudent />} />
				<Route path="dashboard/*" element={<DashboardPage />} />
			</Routes>
		</>
	);
};

export default App;
