import React, { ReactNode } from 'react';

interface IAuthWrapper {
	children: ReactNode;
}

const AuthWrapper = ({ children }: IAuthWrapper) => {
	return <section className="auth-box flex">{children}</section>;
};

export default AuthWrapper;
