import { useDispatch } from 'react-redux';
import { TabTitle } from '../../utils/Functions';
import { useNavigate } from 'react-router-dom';
import { Spacer } from '../Auth';

const FormSuccess = () => {
	TabTitle('Payment Confirmation • Brilliance App');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const dispatch = useDispatch();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const navigate = useNavigate();

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		dispatch(updateStudent({ hasPaid: true }));
	// 		navigate('/form');
	// 	}, 3500);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	return (
		<section className="auth-box flex">
			<div className="br-pay-ui auth-paper">
				<img
					className="auth-app-logo"
					src={require('../../assets/pay-money.png')}
					alt=""
					title="Brilliance App"
				/>

				<h2 className="bri-h2">Successfully Submitted Admission Data</h2>

				<div className="br-pay-content flex">
					<div
						className="br-pay-amount"
						style={{
							width: 'unset',
							height: '90px',
							borderRadius: '10px',
							padding: '10px 20px',
						}}
					>
						House 1: Nkrumah
					</div>
					<div className="br-pay-subtext">
						Check <b>your MTN Mobile Money account</b> and <b>enter your PIN</b>{' '}
						in the Pop-Up menu to complete the transaction to move to the next
						stage.
						<br />
						<br />
						<b>
							<i>OR</i>
						</b>
						<br />
						<br />
						Dial <b>*170#</b> and check <b>your Approvals</b> to complete
						process.
					</div>

					<Spacer />
				</div>
			</div>
		</section>
	);
};

export default FormSuccess;
