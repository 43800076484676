import React, { useEffect, useState } from 'react';
import {
	AuthWrapper,
	BRButton,
	BRSelectField,
	BRTextField,
	Spacer,
} from '../Auth';
import {
	IsValidEmail,
	NumbersOnly,
	TabTitle,
	readFromLocalStorage,
	storeInLocalStorage,
} from '../../utils/Functions';
import { REGIONS } from '../../utils/GHANA';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectStudent,
	updateStudent,
} from '../../redux-context/slices/studentSlice';
import { useNavigate } from 'react-router-dom';
import { RELIGIONS } from '../../utils/data';
import { gql, useMutation } from '@apollo/client';

const UPDATE_STUDENT = gql`
	mutation UpdateStudent(
		$indexNumber: String!
		$hometown: String!
		$hometownRegion: String!
		$permanentAddress: String!
		$religiousDenomination: String!
		$nameOfFather: String
		$fatherOccupation: String
		$fathersContact: String
		$fathersEmail: String
		$nameOfMother: String
		$mothersOccupation: String
		$mothersContact: String
		$mothersEmail: String
	) {
		editStudent(
			indexNumber: $indexNumber
			hometown: $hometown
			hometownRegion: $hometownRegion
			permanentAddress: $permanentAddress
			religiousDenomination: $religiousDenomination
			nameOfFather: $nameOfFather
			fatherOccupation: $fatherOccupation
			fathersContact: $fathersContact
			fathersEmail: $fathersEmail
			nameOfMother: $nameOfMother
			mothersOccupation: $mothersOccupation
			mothersContact: $mothersContact
			mothersEmail: $mothersEmail
		) {
			id
			indexNumber
			fullName
			programme
			hometown
		}
	}
`;

const DoDecaFrom = () => {
	TabTitle('Demographic Information - Brilliance App');

	const dispatch = useDispatch();
	const student = useSelector(selectStudent);
	const cacheStudent = JSON.parse(readFromLocalStorage('student')!);
	const navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isPending, setIsPending] = useState(false);

	const longCheck =
		(student.hometown &&
			student.hometownRegion &&
			student.permanentAddress &&
			student.religiousDenomination) ||
		(student.fathersContact.length > 0 &&
			student.fathersContact.length === 10) ||
		(student.fathersEmail.length > 0 && IsValidEmail(student.fathersEmail)) ||
		(student.mothersContact.length > 0 &&
			student.mothersContact.length === 10) ||
		(student.mothersEmail.length > 0 && IsValidEmail(student.mothersEmail));

	/** Handle Form Button Click */
	const handleSubmit = () => {
		setIsSubmitting(true);
		setIsPending(true);

		if (!longCheck) {
			setIsPending(false);
			return;
		}

		editStudent({
			variables: {
				indexNumber: cacheStudent.indexNumber.toString(),
				hometown: student.hometown,
				hometownRegion: student.hometownRegion,
				permanentAddress: student.permanentAddress,
				religiousDenomination: student.religiousDenomination,
				nameOfFather: student.nameOfFather,
				fatherOccupation: student.fatherOccupation,
				fathersContact: student.fathersContact,
				fathersEmail: student.fathersEmail,
				nameOfMother: student.nameOfMother,
				mothersOccupation: student.mothersOccupation,
				mothersContact: student.mothersContact,
				mothersEmail: student.mothersEmail,
			},
		})
			.then((res) => {
				setIsPending(false);
				navigate('/form/triskaideca');
			})
			.catch((err) => {
				console.log('ERR: ', err.message);
				setIsPending(false);
			});
	};

	const [editStudent] = useMutation(UPDATE_STUDENT);

	useEffect(() => {
		storeInLocalStorage('student', JSON.stringify(student));
	}, [student]);

	return (
		<AuthWrapper>
			<div className="form-paper deca-paper">
				<h2 className="bri-h2">Demographic Information</h2>

				<section className="form-content form-with-pasport">
					{/* Details */}
					<div className="form-details double-col">
						<BRTextField
							type={'text'}
							placeholder={'Hometown'}
							onChange={(e: any) =>
								dispatch(updateStudent({ hometown: e.target.value }))
							}
							error={
								isSubmitting && student.hometown === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRSelectField
							placeholder={'Hometown Region'}
							options={REGIONS.map((reg) => reg.region)}
							onChange={(e: any) =>
								dispatch(updateStudent({ hometownRegion: e.target.value }))
							}
							error={
								isSubmitting && student.hometownRegion === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={'Permanent Address'}
							onChange={(e: any) =>
								dispatch(updateStudent({ permanentAddress: e.target.value }))
							}
							error={
								isSubmitting && student.permanentAddress === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRSelectField
							placeholder={'Religious Denomination'}
							options={RELIGIONS}
							onChange={(e: any) =>
								dispatch(
									updateStudent({ religiousDenomination: e.target.value })
								)
							}
							error={
								isSubmitting && student.religiousDenomination === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={'Name of Father (optional)'}
							onChange={(e: any) =>
								dispatch(updateStudent({ nameOfFather: e.target.value }))
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={"Father's Occupation(optional)"}
							onChange={(e: any) =>
								dispatch(updateStudent({ fatherOccupation: e.target.value }))
							}
						/>

						<BRTextField
							type={'number'}
							placeholder={"Father's Contact (optional)"}
							onChange={(e: any) =>
								dispatch(updateStudent({ fathersContact: e.target.value }))
							}
							min={10}
							max={10}
							onKeyPress={NumbersOnly}
							error={
								student.fathersContact.length > 0 &&
								student.fathersContact.length < 10
									? 'Contact is 10 characters long'
									: ''
							}
						/>

						<BRTextField
							type={'email'}
							placeholder={"Father's Email (optional)"}
							onChange={(e: any) =>
								dispatch(updateStudent({ fathersEmail: e.target.value }))
							}
							error={
								isSubmitting &&
								student.fathersEmail.length > 0 &&
								!IsValidEmail(student.fathersEmail)
									? 'Provide a valid email address'
									: ''
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={'Name of Mother (optional)'}
							onChange={(e: any) =>
								dispatch(updateStudent({ nameOfMother: e.target.value }))
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={"Mother's Occupation (optional)"}
							onChange={(e: any) =>
								dispatch(updateStudent({ mothersOccupation: e.target.value }))
							}
						/>

						<BRTextField
							type={'number'}
							placeholder={"Mother's Contact (optional)"}
							onChange={(e: any) =>
								dispatch(updateStudent({ mothersContact: e.target.value }))
							}
							min={10}
							max={10}
							onKeyPress={NumbersOnly}
							error={
								student.mothersContact.length > 0 &&
								student.mothersContact.length < 10
									? 'Contact is 10 characters long'
									: ''
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={"Mother's Email (optional)"}
							onChange={(e: any) =>
								dispatch(updateStudent({ mothersEmail: e.target.value }))
							}
							error={
								isSubmitting &&
								student.mothersEmail.length > 0 &&
								!IsValidEmail(student.mothersEmail)
									? 'Provide a valid email address'
									: ''
							}
						/>
					</div>

					<div style={{ clear: 'both' }}></div>
					<Spacer />

					<div className="form-btn-box">
						<BRButton
							text={'Next'}
							onClick={handleSubmit}
							isProcessing={isPending}
						/>
					</div>

					<Spacer size={20} />
				</section>
			</div>
		</AuthWrapper>
	);
};

export default DoDecaFrom;
