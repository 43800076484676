import { useEffect, useState } from 'react';
import { AuthWrapper, BRButton, BRTextField, Spacer } from '../Auth';
import {
	NumbersOnly,
	TabTitle,
	randNum,
	readFromLocalStorage,
	storeInLocalStorage,
} from '../../utils/Functions';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectStudent,
	updateStudent,
} from '../../redux-context/slices/studentSlice';
import { useNavigate } from 'react-router-dom';
import { INTERESTS } from '../../utils/data';
import { gql, useMutation } from '@apollo/client';

const UPDATE_STUDENT = gql`
	mutation UpdateStudent(
		$indexNumber: String!
		$nameOfGuardian: String!
		$relationshipToApplicant: String!
		$guardianContact: String!
		$guardiansEmail: String
		$interestOfApplicant: [String]!
		$residentialAddressOfGuardian: String
		$otherTelephoneContact: String
		$schoolAwards: String
		$positionHeldByApplicant: String
		$acceptedAdmission: Boolean!
		$dateCreated: String
		$modifiedBy: String
	) {
		editStudent(
			indexNumber: $indexNumber
			nameOfGuardian: $nameOfGuardian
			relationshipToApplicant: $relationshipToApplicant
			guardianContact: $guardianContact
			guardiansEmail: $guardiansEmail
			interestOfApplicant: $interestOfApplicant
			residentialAddressOfGuardian: $residentialAddressOfGuardian
			otherTelephoneContact: $otherTelephoneContact
			schoolAwards: $schoolAwards
			positionHeldByApplicant: $positionHeldByApplicant
			acceptedAdmission: $acceptedAdmission
			dateCreated: $dateCreated
			modifiedBy: $modifiedBy
		) {
			id
			indexNumber
			fullName
			programme
			aggregate
		}
	}
`;

const TriskaiDecaForm = () => {
	TabTitle('Guardian & Interests Information - Brilliance App');
	const admissionCheckId = `admit-${randNum()}`;

	const dispatch = useDispatch();
	const student = useSelector(selectStudent);
	const cacheStudent = JSON.parse(readFromLocalStorage('student')!);
	const navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isPending, setIsPending] = useState(false);

	/** Handle Interests Checkboxes */
	const handleChange = (e: any) => {
		const { value } = e.target;
		if (student.interestOfApplicant.includes(value)) {
			dispatch(
				updateStudent({
					interestOfApplicant: student.interestOfApplicant.filter(
						(v: string) => v !== value
					),
				})
			);
		} else {
			dispatch(
				updateStudent({
					interestOfApplicant: [...student.interestOfApplicant, value],
				})
			);
		}
	};

	const longCheck =
		student.nameOfGuardian &&
		student.relationshipToApplicant &&
		student.guardianContact.length > 0 &&
		student.guardianContact.length === 10 &&
		student.interestOfApplicant.length > 0 &&
		student.acceptedAdmission;

	/** Handle Form Button Click */
	const handleSubmit = () => {
		setIsSubmitting(true);
		setIsPending(true);

		if (!longCheck) {
			setTimeout(() => {
				setIsPending(false);
			}, 1000);
			return;
		}

		const timeNow = new Date().toString();
		// setTimeout(() => {
		// 	dispatch(updateStudent({ dateCreated: timeNow }));
		// 	navigate('/form/admission-success');
		// }, 2500);

		editStudent({
			variables: {
				indexNumber: cacheStudent.indexNumber.toString(),
				nameOfGuardian: student.nameOfGuardian,
				relationshipToApplicant: student.relationshipToApplicant,
				guardianContact: student.guardianContact,
				guardiansEmail: student.guardiansEmail,
				interestOfApplicant: student.interestOfApplicant,
				residentialAddressOfGuardian: student.residentialAddressOfGuardian,
				otherTelephoneContact: student.otherTelephoneContact,
				schoolAwards: student.schoolAwards,
				positionHeldByApplicant: student.positionHeldByApplicant,
				acceptedAdmission: true,
				dateCreated: timeNow,
				modifiedBy: student.fullName,
			},
		})
			.then((res) => {
				setIsPending(false);
				console.log('res: ', res);

				navigate(`/print/${res?.data?.editStudent?.id}`);
			})
			.catch((err) => {
				console.log('ERR: ', err.message);
				setIsPending(false);
			});
	};

	const [editStudent] = useMutation(UPDATE_STUDENT);

	useEffect(() => {
		storeInLocalStorage('student', JSON.stringify(student));
	}, [student]);

	return (
		<AuthWrapper>
			<div className="form-paper deca-paper">
				<h2 className="bri-h2">Guardian & Interests Information</h2>

				<section className="form-content form-with-pasport">
					{/* Details */}
					<div className="form-details double-col">
						<BRTextField
							type={'text'}
							placeholder={'Name of Guardian'}
							onChange={(e: any) =>
								dispatch(updateStudent({ nameOfGuardian: e.target.value }))
							}
							error={
								isSubmitting && student.nameOfGuardian === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={'Relationship to the Applicant'}
							onChange={(e: any) =>
								dispatch(
									updateStudent({ relationshipToApplicant: e.target.value })
								)
							}
							error={
								isSubmitting && student.relationshipToApplicant === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRTextField
							type={'number'}
							placeholder={'Contact of Guardian'}
							onChange={(e: any) =>
								dispatch(updateStudent({ guardianContact: e.target.value }))
							}
							min={10}
							max={10}
							onKeyPress={NumbersOnly}
							error={
								isSubmitting && student.guardianContact.length < 10
									? 'Contact is 10 characters long'
									: ''
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={"Guardian's email (optional)"}
							onChange={(e: any) =>
								dispatch(updateStudent({ guardiansEmail: e.target.value }))
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={'Residential Address of Guardian (optional)'}
							onChange={(e: any) =>
								dispatch(
									updateStudent({
										residentialAddressOfGuardian: e.target.value,
									})
								)
							}
						/>

						<BRTextField
							type={'number'}
							placeholder={'Other Telephone Contact (optional)'}
							onChange={(e: any) =>
								dispatch(
									updateStudent({ otherTelephoneContact: e.target.value })
								)
							}
							min={10}
							max={10}
							onKeyPress={NumbersOnly}
							error={
								student.otherTelephoneContact.length > 0 &&
								student.otherTelephoneContact.length < 10
									? 'Contact is 10 characters long'
									: ''
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={'School Awards (optional)'}
							onChange={(e: any) =>
								dispatch(updateStudent({ schoolAwards: e.target.value }))
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={'Position Held by Applicant (optional)'}
							onChange={(e: any) =>
								dispatch(
									updateStudent({ positionHeldByApplicant: e.target.value })
								)
							}
						/>
					</div>

					<fieldset className="fieldset-form">
						<legend>Interest(s) of Applicant</legend>

						<div className="fieldset-form-content flex">
							{INTERESTS.map((interest, id) => (
								<label key={`fset-${id}`} className="flex">
									<input
										type="checkbox"
										name="studentInterest"
										value={interest}
										className="hidebx"
										onChange={handleChange}
									/>
									<div className="box"></div>
									<span>{interest}</span>
								</label>
							))}
						</div>
						{isSubmitting && student.interestOfApplicant.length < 1 ? (
							<div className="br-error">Field is required</div>
						) : (
							''
						)}
					</fieldset>

					<label htmlFor={admissionCheckId} className="accept-admission flex">
						<input
							type="checkbox"
							id={admissionCheckId}
							className="hidebx"
							onChange={(e: any) =>
								dispatch(updateStudent({ acceptedAdmission: e.target.checked }))
							}
						/>
						<div className="box"></div>
						<div className="accept-checkbox">
							By checking this, I do accept that my admission into this school
							opens a new chapter in my life. I therefore pledge to abide by all
							the rules and regulations ofthe school. <span>(required)</span>
						</div>
					</label>
					{isSubmitting && !student.acceptedAdmission ? (
						<div className="br-error">Field is required</div>
					) : (
						''
					)}
					<div style={{ clear: 'both' }}></div>
					{window.screen.width < 999 && <Spacer />}

					<div className="form-btn-box">
						<BRButton
							text={'Submit'}
							onClick={handleSubmit}
							isProcessing={isPending}
						/>
					</div>

					<Spacer size={20} />
				</section>
			</div>
		</AuthWrapper>
	);
};

export default TriskaiDecaForm;
