import { useEffect, useState } from 'react';
import {
	AuthWrapper,
	BRButton,
	BRSelectField,
	BRTextField,
	Spacer,
} from '../Auth';
import { TabTitle, storeInLocalStorage } from '../../utils/Functions';
import { REGIONS } from '../../utils/GHANA';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectStudent,
	updateStudent,
} from '../../redux-context/slices/studentSlice';
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

const UPDATE_STUDENT = gql`
	mutation UpdateStudent(
		$indexNumber: String!
		# $aggregate: Int!
		$JHSattended: String!
		$JHSregion: String!
		$JHSdistrict: String!
		$JHStown: String!
	) {
		editStudent(
			indexNumber: $indexNumber
			# aggregate: $aggregate
			JHSattended: $JHSattended
			JHSregion: $JHSregion
			JHSdistrict: $JHSdistrict
			JHStown: $JHStown
		) {
			id
			indexNumber
			fullName
			programme
			aggregate
		}
	}
`;

const UnDecaForm = () => {
	TabTitle('Academic Information - Brilliance App');

	const dispatch = useDispatch();
	const student = useSelector(selectStudent);
	const navigate = useNavigate();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isPending, setIsPending] = useState(false);

	const longCheck =
		// student.aggregate &&
		student.JHSattended &&
		student.JHSregion &&
		student.JHSdistrict &&
		student.JHStown;

	/** Handle Form Button Click */
	const handleSubmit = () => {
		setIsSubmitting(true);
		setIsPending(true);

		if (!longCheck) {
			// setTimeout(() => {
			setIsPending(false);
			// }, 1000);
			return;
		}

		editStudent({
			variables: {
				indexNumber: student.indexNumber.toString(),
				// aggregate: Number(student.aggregate),
				JHSattended: student.JHSattended,
				JHSregion: student.JHSregion,
				JHSdistrict: student.JHSdistrict,
				JHStown: student.JHStown,
			},
		})
			.then((res) => {
				setIsPending(false);
				navigate('/form/dodeca');
			})
			.catch((err) => {
				console.log('ERR: ', err.message);
				setIsPending(false);
			});
	};

	const [editStudent] = useMutation(UPDATE_STUDENT);

	useEffect(() => {
		storeInLocalStorage('student', JSON.stringify(student));
	}, [student]);

	return (
		<AuthWrapper>
			<div className="auth-paper">
				<h2 className="bri-h2">Academic Information</h2>

				<section className="form-content form-with-pasport">
					{/* Details */}
					<div className="form-details">
						{/* <BRTextField
							type={'number'}
							placeholder={'Aggregate'}
							onChange={(e: any) =>
								dispatch(updateStudent({ aggregate: e.target.value }))
							}
							min={1}
							max={2}
							onKeyPress={NumbersOnly}
							error={
								(isSubmitting && student.aggregate < 6) ||
								student.aggregate > 54
									? 'Aggregate is between 6 and 54'
									: ''
							}
						/> */}

						<BRTextField
							type={'text'}
							placeholder={'JHS Attended'}
							onChange={(e: any) =>
								dispatch(updateStudent({ JHSattended: e.target.value }))
							}
							error={
								isSubmitting && student.JHSattended === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRSelectField
							placeholder={'JHS Region'}
							options={REGIONS.map((reg) => reg.region)}
							onChange={(e: any) =>
								dispatch(updateStudent({ JHSregion: e.target.value }))
							}
							error={
								isSubmitting && student.JHSregion === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRSelectField
							placeholder={'JHS District'}
							isDisabled={student.JHSregion === '' ? true : false}
							options={
								student.JHSregion
									? REGIONS.filter(
											(reg) => reg.region === student.JHSregion
									  )[0].districts.map((d) => d)
									: ['']
							}
							onChange={(e: any) =>
								dispatch(updateStudent({ JHSdistrict: e.target.value }))
							}
							error={
								isSubmitting && student.JHSdistrict === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={'JHS Town'}
							onChange={(e: any) =>
								dispatch(updateStudent({ JHStown: e.target.value }))
							}
							error={
								isSubmitting && student.JHStown === ''
									? 'Field is required'
									: ''
							}
						/>
					</div>

					<div style={{ clear: 'both' }}></div>
					<Spacer />

					<div className="form-btn-box">
						<BRButton
							text={'Next'}
							onClick={handleSubmit}
							isProcessing={isPending}
						/>
					</div>

					<Spacer size={20} />
				</section>
			</div>
		</AuthWrapper>
	);
};

export default UnDecaForm;
