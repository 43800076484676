import React from 'react';

interface IDashTicket {
	title: string;
	value: string;
	icon: string;
}

const DashTicket = ({ title, value, icon }: IDashTicket) => {
	return (
		<div className="dash-ticket flex">
			<img src={icon} alt={title} />

			<div className="dash-ticket-box flex">
				<div className="value">{value}</div>
				<div className="title">{title}</div>
			</div>
		</div>
	);
};

export default DashTicket;
