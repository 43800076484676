export const REGIONS = [
	{
		region: 'Ahafo',
		capital: 'Goaso',
		districts: [
			'Asunafo North Municipal',
			'Asunafo South District',
			'Asutifi North District',
			'Asutifi South District',
			'Tano North Municipal',
			'Tano South Municipal',
		],
	},
	{
		region: 'Ashanti',
		capital: 'Kumasi',
		districts: [
			'Adansi North District',
			'Adansi South District',
			'Afigya Kwabre South District',
			'Ahafo Ano North Municipal',
			'Ahafo Ano South West District',
			'Amansie Central District',
			'Asante Akim Central Municipal',
			'Asante Akim North Municipal',
			'Asante Akim South Municipal',
			'Asokore Mampong Municipal',
			'Atwima Kwanwoma District',
			'Atwima Mponua District',
			'Atwima Nwabiagya Municipal',
			'Bekwai Municipal',
			'Bosome Freho District',
			'Bosomtwe District',
			'Ejisu Municipal',
			'Ejura Sekyedumase Municipal',
			'Kumasi Metropolitan',
			'Kwabre East Municipal',
			'Mampong Municipal ',
			'Obuasi Municipal',
			'Offinso Municipal',
			'Offinso North District',
			'Sekyere Afram Plains District',
			'Sekyere East District',
			'Sekyere Kumawu District',
			'Sekyere South District',
			'Oforikrom Municipal',
			'Kwadaso Municipal',
			'Old Tafo Municipal',
			'Asokwa Municipal',
			'Suame Municipal',
			'Ahafo Ano South East District',
			'Amansie South District',
			'Atwima Nwabiagya North District',
			'Akrofuom District',
			'Adansi Asokwa District',
			'Obuasi East District',
			'Afigya Kwabre North District',
		],
	},
	{
		region: 'Bono',
		capital: 'Sunyani',
		districts: [
			'Banda District',
			'Berekum East Municipal',
			'Dormaa Central Municipal',
			'Dormaa East District',
			'Dormaa West District',
			'Jaman North District',
			'Jaman South Municipal',
			'Sunyani Municipal',
			'Sunyani West Municipal',
			'Tain District',
			'Wenchi Municipal',
			'Berekum West District',
		],
	},
	{
		region: 'Bono East',
		capital: 'Techiman',
		districts: [
			'Atebubu Amantin Municipal',
			'Kintampo North Municipal',
			'Kintampo South District',
			'Nkoranza South Municipal ',
			'Nkoranza  North District',
			'Pru East District',
			'Sene West District',
			'Techiman Municipal',
			'Techiman North District',
			'Pru West District',
		],
	},
	{
		region: 'Central',
		capital: 'Cape Coast',
		districts: [
			'Abura Asebu Kwamankese District',
			'Agona East District',
			'Agona West Municipal',
			'Ajumako Enyan Essiam District',
			'Asikuma Odoben Brankwa District',
			'Assin Foso Municipal',
			'Assin South District',
			'Awutu Senya East Municipal',
			'Awutu Senya West District',
			'Cape Coast Metropolitan',
			'Effutu Municipal',
			'Ekumfi District',
			'Gomoa Central District',
			'Gomoa West District',
			'Komenda Edina Eguafo Abirem Municipal',
			'Mfantseman Municipal',
			'Twifo Atti Morkwa District',
			'Twifo Heman Lower Denkyira District',
			'Upper Denkyira East Municipal',
			'Upper Denkyira West District',
			'Assin North District',
			'Gomoa East District',
		],
	},
	{
		region: 'Eastern',
		capital: 'Koforidua',
		districts: [
			'Akuapem North Municipal',
			'Akwapim South District',
			'Akyemansa District',
			'Asuogyaman District',
			'Atiwa West District',
			'Ayensuano District',
			'Birim Central Municipal',
			'Birim North District',
			'Denkyembour District',
			'Abuakwa South Municipal',
			'Fanteakwa North District',
			'Kwaebibirem Municipal',
			'Kwahu Afram Plains North District',
			'Kwahu Afram Plains South District',
			'Kwahu East District',
			'Kwahu South Municipal',
			'Kwahu West Municipal',
			'Lower Manya Krobo Municipal',
			'New Juaben South Municipal',
			'Nsawam Adoagyiri Municipal',
			'Suhum Municipal',
			'Upper Manya Krobo District',
			'UPPER WESTest Akim District',
			'West Akim Municipal',
			'Yilo Krobo Municipal',
			'Okere District',
			'Atiwa East District',
			'Fanteakwa South District',
			'Asene Manso Akroso District',
			'Abuakwa North Municipal',
			'New Juaben North Municipal',
			'Achiase District',
		],
	},
	{
		region: 'Greater Accra',
		capital: 'Accra',
		districts: [
			'Accra Metropolitan',
			'Ada West District',
			'Ada East District',
			'Adentan Municipal',
			'Ashaiman Municipal',
			'Ga Central Municipal',
			'Ga South Municipal',
			'Ga East Municipal',
			'Ga West Municipal',
			'La Dade Kotopon Municipal',
			'Ledzokuku Municipal',
			'La Nkwantanang Madina Municipal',
			'Ningo Prampram District',
			'Shai Osudoku District',
			'Tema Metropolitan',
			'Kpone Katamanso Municipal',
			'Okaikwei North Municipal',
			'Ablekuma North Municipal',
			'Ablekuma West Municipal',
			'Ayawaso East Municipal',
			'Ayawaso North Municipal',
			'Ayawaso West Municipal',
			'Ga North Municipal',
			'Weija Gbawe Municipal',
			'Tema West Municipal',
			'Krowor Municipal',
			'Korle Klottey Municipal',
			'Ablekuma Central Municipal',
			'Ayawaso Central Municipal',
		],
	},
	{
		region: 'North East',
		capital: 'Nalerigu',
		districts: [
			'Bunkpurugu Nyankpanduri District',
			'Chereponi District',
			'East Mamprusi Municipal',
			'Mamprugu Moagduri District',
			'West Mamprusi Municipal',
			'Yunyoo-Nasuan District',
		],
	},
	{
		region: 'Northern',
		capital: 'Tamale',
		districts: [
			'Gushegu Municipal',
			'Karaga District',
			'Kpandai District',
			'Kumbungu District',
			'Mion District',
			'Nanumba North Municipal',
			'Nanumba South District',
			'Saboba District',
			'Sagnarigu Municipal',
			'Savelugu Municipal',
			'Tamale Metropolitan',
			'Tatale/Sanguli District',
			'Tolon District',
			'Yendi Municipal',
			'Zabzugu District',
			'Nanton District',
		],
	},
	{
		region: 'Oti',
		capital: 'Dambai',
		districts: [
			'Biakoye District',
			'Jasikan District',
			'Kadjebi District',
			'Krachi East Municipal',
			'Krachi Nchumuru District',
			'Krachi West District',
			'Nkwanta North District',
			'Nkwanta South Municipal',
			'Guan District',
		],
	},
	{
		region: 'Savannah',
		capital: 'Damongo',
		districts: [
			'Bole District',
			'Central Gonja District',
			'East Gonja Municipal',
			'North Gonja District',
			'Sawla-Tuna-Kalba District',
			'North East Gonja District',
		],
	},
	{
		region: 'Upper East',
		capital: 'Bolgatanga',
		districts: [
			'Bawku Municipal',
			'Bawku West District',
			'Binduri District',
			'Bolgatanga Municipal',
			'Bongo District',
			'Builsa North Municipal',
			'Builsa South District',
			'Garu District',
			'Kassena Nankana Municipal',
			'Kassena Nankana West District',
			'Nabdam District',
			'Pusiga District',
			'Talensi District',
			'Bolgatanga East District',
			'Tempane District',
		],
	},
	{
		region: 'Upper West',
		capital: 'Wa',
		districts: [
			'Daffiama Bussie Issa District',
			'Jirapa Municipal',
			'Lambussie Karni District',
			'Lawra Municipal',
			'Nadowli Kaleo District',
			'Nandom Municipal',
			'Sissala East Municipal',
			'Sissala West District',
			'Wa East District',
			'Wa Municipal',
			'Wa West District',
		],
	},
	{
		region: 'Volta',
		capital: 'Ho',
		districts: [
			'Afadzato South District',
			'Agotime Ziope District',
			'Akatsi South Municipal',
			'Central Tongu District',
			'Ho Municipal',
			'Ho West District',
			'Hohoe Municipal',
			'Keta Municipal',
			'Ketu North Municipal',
			'Ketu South Municipal',
			'Kpando Municipal',
			'North Dayi District',
			'North Tongu District',
			'South Dayi District',
			'South Tongu District',
			'Anloga District',
		],
	},
	{
		region: 'Western',
		capital: 'Senkondi-Takoradi',
		districts: [
			'Ahanta West Municipal',
			'Amenfi Central District',
			'Wassa Amenfi East Municipal',
			'Amenfi West Municipal',
			'Ellembelle District',
			'Jomoro Municipal',
			'Nzema East Municipal',
			'Prestea-Huni Valley Municipal',
			'Sekondi Takoradi Metropolitan',
			'Shama District',
			'Tarkwa Nsuaem Municipal',
			'Wassa East District',
			'Effia Kwesimintsim Municipal',
		],
	},
	{
		region: 'Western North',
		capital: 'Sefwi Wiaso',
		districts: [
			'Aowin Municipal',
			'Bia East District',
			'Bia West District',
			'Bibiani-Anhwiaso Bekwai Municipal',
			'Bodi District',
			'Juaboso District',
			'Sefwi Akontombra District',
			'Sefwi Wiawso Municipal',
			'Suaman District',
		],
	},
];
