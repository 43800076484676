import { useDispatch, useSelector } from 'react-redux';
import {
	selectStudentInfoOpen,
	toggleStudentInfoOpen,
	updateEditID,
} from '../../redux-context/slices/appSlice';
import { Student } from '../../types/Student';
import { Tooltip } from 'react-tooltip';
import { Preloader } from '.';

interface IStudentTable {
	size: number;
	data?: Student[];
	page?: number;
	total?: number;
	showClass?: boolean;
	isLoading?: boolean;
}

const StudentTable = ({
	size = 20,
	data,
	page = 1,
	total = 1,
	showClass = false,
	isLoading = false,
}: IStudentTable) => {
	const dispatch = useDispatch();
	const studentInfoOpen = useSelector(selectStudentInfoOpen);

	const handleToggleInfoOpen = (id: string) => {
		dispatch(updateEditID(id));
		!studentInfoOpen && dispatch(toggleStudentInfoOpen());
	};

	return (
		<aside className="dash-paper student-table">
			{isLoading ? (
				<Preloader />
			) : (
				<table>
					<thead>
						<tr>
							<th></th>
							<th></th>
							<th>Name</th>
							<th>Index No.</th>
							<th>Enrollment Code</th>
							<th>Guardian Contact</th>
							{!showClass ? <th>Programme</th> : <th>Class</th>}
							<th></th>
						</tr>
					</thead>
					<tbody>
						{data?.map((student, i) => (
							<tr
								key={`tr-0${student?.id}`}
								onClick={() => student?.id && handleToggleInfoOpen(student.id)}
							>
								<td className="td-num">{(page - 1) * size + i + 1}.</td>
								<td>
									<div className="td-pic">
										{student?.passportPhoto && (
											<img
												src={student?.passportPhoto}
												alt={student?.fullName}
											/>
										)}
									</div>
								</td>
								<td>{student?.fullName}</td>
								<td>{student?.indexNumber}</td>
								<td>{student?.enrolmentCode}</td>
								<td>{student?.guardianContact}</td>
								{!showClass ? (
									<td>{student?.programme}</td>
								) : (
									<td>{student?.class}</td>
								)}
								<td>
									<Tooltip anchorId={student?.id} />
									{student?.hasPaid ? (
										<div
											id={student?.id}
											className="hasPaid-flag"
											data-tooltip-content={'Student has Paid'}
											data-tooltip-place="top"
										/>
									) : (
										''
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}

			<footer className="table-footer-page-count">{`${page} of ${total}`}</footer>
		</aside>
	);
};

export default StudentTable;
