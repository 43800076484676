import React from 'react';
import { Link } from 'react-router-dom';

interface IDashCourse {
	title: string;
	total: string;
	icon: string;
	to: string;
}

const DashCourse = ({ title, total, icon, to }: IDashCourse) => {
	return (
		<Link to={to} className="dash-course-box flex">
			<br />
			<img src={icon} alt={title} />
			<div className="title">{title}</div>
			<div className="total">Total Students: {total}</div>
		</Link>
	);
};

export default DashCourse;
