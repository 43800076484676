import { Route, Routes, useNavigate } from 'react-router-dom';
import {
	Dashboard,
	LeftMenu,
	MobileNav,
	ProgrammeDetails,
	Programmes,
	Settings,
	StudentInfo,
	Students,
	UploadData,
} from '../components/Dashboard';
import '../css/Dashboard.css';
import { selectStudentInfoOpen } from '../redux-context/slices/appSlice';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { readFromLocalStorage, storeInLocalStorage } from '../utils/Functions';
import { Spacer } from '../components/Auth';

const DashboardPage = () => {
	const studentInfoOpen = useSelector(selectStudentInfoOpen);
	const hasLoggedIn = JSON.parse(readFromLocalStorage('useWrite')!);
	const navigate = useNavigate();

	useEffect(() => {
		if (!hasLoggedIn) navigate('/auth');
	}, [hasLoggedIn, navigate]);

	// Logout After 15 Minutes of inactivity
	const [hasBeenActive, setHasBeenActive] = useState(true);

	useEffect(() => {
		let inactivityTimer = setTimeout(() => {
			setHasBeenActive(false);
		}, 15 * 60 * 1000); // 15 minutes in milliseconds

		const handleActivity = () => {
			clearTimeout(inactivityTimer);
			inactivityTimer = setTimeout(() => {
				setHasBeenActive(false);
			}, 15 * 60 * 1000); // 15 minutes in milliseconds
		};

		window.addEventListener('mousemove', handleActivity);
		window.addEventListener('keypress', handleActivity);

		return () => {
			window.removeEventListener('mousemove', handleActivity);
			window.removeEventListener('keypress', handleActivity);
		};
	}, []);

	useEffect(() => {
		const handleResetTimer = () => {
			setHasBeenActive(true);
		};

		window.addEventListener('mousemove', handleResetTimer);
		window.addEventListener('keypress', handleResetTimer);

		return () => {
			window.removeEventListener('mousemove', handleResetTimer);
			window.removeEventListener('keypress', handleResetTimer);
		};
	}, []);

	useEffect(() => {
		if (!hasBeenActive) {
			storeInLocalStorage('useWrite', false);
		}
	}, [hasBeenActive]);

	return (
		<>
			<MobileNav />
			<div className="dashboard-page flex">
				<LeftMenu />
				<div className="dashboard-content-box">
					{window.screen.width < 999 && <Spacer size={65} />}
					<aside
						className="dashboard"
						style={{
							overflowY: studentInfoOpen ? 'hidden' : 'auto',
						}}
					>
						<Routes>
							<Route path="/" element={<Dashboard />} />
							<Route path="students" element={<Students />} />
							<Route path="upload-data" element={<UploadData />} />
							<Route path="programmes" element={<Programmes />} />
							<Route path="programmes/:course" element={<ProgrammeDetails />} />
							<Route path="settings" element={<Settings />} />
						</Routes>
					</aside>
				</div>
				{/* student info */}
			</div>
			<StudentInfo />
		</>
	);
};

export default DashboardPage;
