export const COURSES = [
	{ name: 'GENERAL SCIENCE', classes: [{ name: '1S1 ' }] },
	{
		name: 'GENERAL ARTS',
		classes: [
			{ name: '1A1' },
			{ name: '1A2' },
			{ name: '1A3' },
			{ name: '1A4' },
			{ name: '1A5' },
			{ name: '1A6' },
			{ name: '1A7' },
		],
	},
	{
		name: 'AGRICULTURE',
		classes: [{ name: '1GA1 ' }],
	},
	{
		name: 'HOME ECONOMICS',
		classes: [{ name: '1H1 ' }, { name: '1H2 ' }],
	},
	{
		name: 'VISUAL ARTS',
		classes: [{ name: '1V1 ' }, { name: '1V2 ' }],
	},
	{
		name: 'BUSINESS',
		classes: [{ name: '1B1 ' }, { name: '1B2 ' }],
	},
];

const INIT_NATIONALITIES: string[] = [];
export const NATIONALITIES = [new Set(...INIT_NATIONALITIES)];

fetch('https://kineticzephyr.onrender.com/nations/all')
	.then((response) => response.json())
	.then((data) => {
		data.nations.forEach((nation: any) => {
			if (nation.demonym) {
				INIT_NATIONALITIES.push(nation!.demonym);
			}
		});
		INIT_NATIONALITIES.sort();
		// console.log(INIT_NATIONALITIES);
	})
	.catch((error) => console.error(error));

export const RELIGIONS = [
	'Christian',
	'Muslim',
	'Hindus',
	'Traditionalist',
	'None',
];

export const INTERESTS = ['Athletics', 'Football', 'Debating Club', 'Others'];

export const HOUSES = [
	'House 1: Akaboha',
	'House 2: Jimiru',
	'House 3: Nkrumah',
	'House 4: Valco',
];
