import { useState } from 'react';
import { BRButton, BRTextField } from '.';
import Spacer from './Spacer';
import { NumbersOnly, TabTitle } from '../../utils/Functions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateStudent } from '../../redux-context/slices/studentSlice';
import { gql, useQuery } from '@apollo/client';

const GET_STUDENT = gql`
	query GetStudent($indexNumber: String!) {
		studentByIndex(indexNumber: $indexNumber) {
			id
			fullName
			indexNumber
		}
	}
`;

/**
 * Shows form to enter index number for validation
 * @returns Index Number AuthenticationPage
 */
const AuthIndexNumber = () => {
	TabTitle('Index Number Verification • Brilliance App');
	const navigate = useNavigate();
	const [error, setError] = useState('');
	const [isPending, setIsPending] = useState(false);
	const [indexNum, setIndexNum] = useState('');
	const dispatch = useDispatch();

	/** Validation for Index Number */
	const handleIndexValidation = (e: any) => {
		TabTitle('Index Number • Brilliance App');
		NumbersOnly(e);
		if (indexNum.length < 9 || indexNum.length > 10) {
			setError('Index Number is 10 characters long');
		} else {
			setError('');
		}
	};

	/** Submit function of index number form */
	const { error: queryError, data } = useQuery(GET_STUDENT, {
		variables: { indexNumber: indexNum },
		skip: indexNum.length < 10,
	});

	const handleIndexVerification = () => {
		setIsPending(true);

		if (indexNum.length < 10) {
			setError('Index Number is 10 characters long');
			setIsPending(false);
			return;
		}

		if (queryError) {
			setError('Index Number not found');
			setIsPending(false);
			return;
		}

		if (!data.studentByIndex) {
			setError('Index Number not found');
			setIsPending(false);
			return;
		}

		setError('');
		dispatch(updateStudent({ indexNumber: indexNum }));

		setTimeout(() => {
			setIsPending(false);
			navigate('/enroll-code');
		}, 1000);
	};

	return (
		<div className="auth-box flex">
			<aside className="auth-paper">
				<img
					className="auth-app-logo"
					src={require('../../assets/brilliance-app.png')}
					alt=""
					title="Brilliance App"
				/>

				<h2 className="bri-h2">Mozano Senior High School</h2>
				{/* <h2 className="bri-h2">Index Number Authentication</h2> */}

				<Spacer size={10} />
				<div className="auth-form flex">
					<BRTextField
						type={'number'}
						placeholder={'Enter Index Number'}
						onChange={(e) => setIndexNum(e.target.value)}
						min={10}
						max={10}
						error={error}
						onKeyPress={handleIndexValidation}
					/>

					<Spacer size={5} />

					<BRButton
						text={'Verify Index Number'}
						onClick={handleIndexVerification}
						isProcessing={isPending}
					/>
				</div>
			</aside>
		</div>
	);
};

export default AuthIndexNumber;
