import { useState } from 'react';
import { BRButton, BRTextField } from '.';
import Spacer from './Spacer';
import { TabTitle } from '../../utils/Functions';
import { useNavigate } from 'react-router-dom';
import {
	selectStudent,
	updateStudent,
} from '../../redux-context/slices/studentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';

const UPDATE_STUDENT = gql`
	mutation UpdateStudent($indexNumber: String!, $enrolmentCode: String!) {
		editStudent(indexNumber: $indexNumber, enrolmentCode: $enrolmentCode) {
			id
			indexNumber
			fullName
			enrolmentCode
		}
	}
`;

/**
 * Shows form to enter enrollment code for validation
 * @returns Enrollment Code Authentication Page
 */
const AuthEnrolmentCode = () => {
	TabTitle('Enrollment Code • Brilliance App');
	const navigate = useNavigate();
	const [error, setError] = useState({
		fullName: '',
		enroll: '',
	});
	const [isPending, setIsPending] = useState(false);
	const [enrollCode, setEnrollCode] = useState('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [fullName, setFullName] = useState('');
	const dispatch = useDispatch();
	const student = useSelector(selectStudent);

	/** Validation for Enrollment Code */
	const handleEnCodeValidation = (e: any) => {
		setEnrollCode(e.target.value);
		if (enrollCode.length < 6) {
			setError({
				...error,
				enroll: 'Enrolment Code is at least 6 characters long',
			});
		} else {
			setError({ ...error, enroll: '' });
		}
	};

	/** Submit function of enrollment code form */
	const handleEnCodeVerification = () => {
		if (enrollCode.length < 6) {
			setError({
				...error,
				enroll: 'Enrolment Code is at least 6 characters long',
			});
			return;
		}

		setIsPending(true);

		editStudent({
			variables: {
				indexNumber: student.indexNumber.toString(),
				enrolmentCode: enrollCode,
			},
		})
			.then((res) => {
				dispatch(updateStudent({ enrolmentCode: enrollCode }));
				setIsPending(false);
				navigate('/pay-confirm');
			})
			.catch((err) => {
				console.log('ERR: ', err);
				setIsPending(false);
			});
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [editStudent, { loading, error: graphError, data }] =
		useMutation(UPDATE_STUDENT);

	return (
		<div className="auth-box flex">
			<aside className="auth-paper">
				<img
					className="auth-app-logo"
					src={require('../../assets/brilliance-app.png')}
					alt=""
					title="Brilliance App"
				/>

				<h2 className="bri-h2">Enrollment Code</h2>

				<Spacer size={10} />
				<div className="auth-form flex">
					{/* <BRTextField
						type={'text'}
						placeholder={'Full Name'}
						onChange={(e) => setFullName(e.target.value)}
						min={10}
						error={error.fullName}
						onKeyPress={(e: any) => {
							setFullName(e.target.value);
							if (fullName.length < 9) {
								setError({ ...error, fullName: 'Enter your full name' });
							} else {
								setError({ ...error, fullName: '' });
							}
						}}
					/> */}
					{/* <br /> */}
					<BRTextField
						type={'text'}
						placeholder={'Enter CSSPS ID on the Placement Form'}
						onChange={(e) => setEnrollCode(e.target.value)}
						min={6}
						max={10}
						error={error.enroll}
						onKeyPress={handleEnCodeValidation}
					/>

					<Spacer size={5} />

					<BRButton
						text={'Continue'}
						onClick={handleEnCodeVerification}
						isProcessing={isPending}
					/>
				</div>
			</aside>
		</div>
	);
};

export default AuthEnrolmentCode;
