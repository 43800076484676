import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { Clue } from '.';
import { StudentUpload } from '../../types/Student';
import { BRButton, Spacer } from '../Auth';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { TabTitle } from '../../utils/Functions';

const ADD_STUDENT_MUTATION = gql`
	mutation AddStudent(
		$indexNumber: String!
		$fullName: String!
		$gender: String!
		$aggregate: Int!
		$programme: String!
		$residentialStatus: String!
	) {
		addStudent(
			indexNumber: $indexNumber
			fullName: $fullName
			gender: $gender
			aggregate: $aggregate
			programme: $programme
			residentialStatus: $residentialStatus
		) {
			id
			indexNumber
			fullName
			gender
			aggregate
			programme
			residentialStatus
		}
	}
`;

const UploadData = () => {
	const [studentsJSON, setStudentsJSON] = useState<StudentUpload[]>([]);
	const [isUploading, setIsUploading] = useState(false);
	TabTitle('Upload Students Excel Sheet');

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [addStudent, { data, error }] = useMutation(ADD_STUDENT_MUTATION);
	console.log('MUTE ERROR: ', error);

	const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = e.target.files?.[0];
		handleFileUpload(selectedFile!);
	};

	const handleFileUpload = (file: File) => {
		const reader = new FileReader();

		reader.onload = (e) => {
			const data = new Uint8Array(e.target?.result as ArrayBuffer);
			const workbook = XLSX.read(data, { type: 'array' });
			const firstSheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[firstSheetName];
			const rows: any[] = [];

			Object.entries(worksheet).forEach(([columnTitle, cell]) => {
				const column = columnTitle.charAt(0);
				const row = parseInt(columnTitle.substr(1), 10);

				if (!rows[row - 1]) {
					rows[row - 1] = {};
				}

				if (column === 'A') {
					// removes last 2 characters from index number
					rows[row - 1].indexNumber = cell.w.substr(0, cell.w.length - 2);
				} else if (column === 'B') {
					rows[row - 1].fullName = cell.w;
				} else if (column === 'C') {
					rows[row - 1].gender = cell.w;
				} else if (column === 'D') {
					rows[row - 1].aggregate = Number(cell.w);
				} else if (column === 'E') {
					rows[row - 1].programme = cell.w;
				} else if (column === 'F') {
					rows[row - 1].track = cell.w;
				} else if (column === 'G') {
					rows[row - 1].residentialStatus = cell.w;
				}
			});

			setStudentsJSON(rows);
			process.env.NODE_ENV === 'development' && console.log('ROWS: ', rows);
		};

		reader.readAsArrayBuffer(file);
	};

	const [uploadProgress, setUploadProgress] = useState(0);
	const navigate = useNavigate();

	const handleUploadAllStudents = async () => {
		setIsUploading(true);
		setUploadProgress(0);

		try {
			const numStudents = studentsJSON.length;
			let numStudentsUploaded = 0;

			await Promise.all(
				studentsJSON.map(async (student) => {
					student.indexNumber &&
						typeof student.aggregate === 'number' &&
						(await addStudent({
							variables: {
								indexNumber: student.indexNumber,
								fullName: student.fullName,
								gender: student.gender,
								aggregate: student.aggregate,
								programme: student.programme,
								residentialStatus: student.residentialStatus,
							},
						}));

					numStudentsUploaded += 1;
					setUploadProgress((numStudentsUploaded / numStudents) * 100);
				})
			);

			navigate('/dashboard');
		} catch (error) {
			console.error('Error uploading students:', error);
		} finally {
			setIsUploading(false);
			setUploadProgress(0);
		}
	};

	return (
		<section className="upload-data centroid">
			<aside className="upload-box">
				{studentsJSON.length < 1 && (
					<label htmlFor="excel-upload">
						<input
							type="file"
							id="excel-upload"
							className="hidebx"
							onChange={handleFileSelection}
							accept=".xls,.xlsx,.csv"
						/>
						<Clue
							text={
								'Click to upload Excel Sheet with Index Numbers and Full Names of students'
							}
						/>
					</label>
				)}

				{studentsJSON.length > 1 && uploadProgress === 0 && (
					<div className="students-excel-box">
						<h3>
							{studentsJSON.length} Students Identified from the Excel Sheet
						</h3>
						<h4>Do you want to upload?</h4>
						<div style={{ color: 'red', fontSize: 10 }}>{error?.message}</div>
						<Spacer />
						<div className="btn-box flex">
							{!isUploading && (
								<BRButton
									text={'No'}
									onClick={() => setStudentsJSON([])}
									className="br-btn-clearer"
								/>
							)}

							<BRButton
								text={'Yes'}
								onClick={handleUploadAllStudents}
								isProcessing={isUploading}
								className={isUploading ? 'no-events' : ''}
							/>
						</div>
					</div>
				)}

				{uploadProgress > 1 && (
					<div className="students-excel-box">
						<h3>Uploading {studentsJSON.length} Students to Database</h3>
						<Clue text={`${uploadProgress.toFixed(1)}% complete`} />
						<progress id="file" value={uploadProgress} max="100">
							{uploadProgress}%
						</progress>
					</div>
				)}
			</aside>
		</section>
	);
};

export default UploadData;
