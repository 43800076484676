import React, { useState } from 'react';
import { randNum } from '../../utils/Functions';

interface IBRSelectField {
	options: any[];
	error?: string;
	placeholder: string;
	onChange: (e: any) => void | (() => void) | any;
	icon?: string;
	value?: string | number;
	isDisabled?: boolean;
}

/**
 * Text Field Component can hold numbers and strings
 * @param {string} type - could be text or number
 * @param {string} placeholder - text to be used as placeholder
 * @param {() => void} onChange - the function to be run on change
 * @param {string} icon - the icon to be displayed on the right
 * @returns Text Field Component
 */
const BRSelectField = ({
	options,
	error,
	placeholder,
	onChange,
	icon,
	isDisabled = false,
}: IBRSelectField) => {
	const [selected, setSelected] = useState('');
	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelected(e.target.value);
		onChange(e);
	};

	return (
		<div className="br-field">
			<div className="tsa-text-field">
				<select
					name={placeholder}
					className="br-select-box"
					disabled={isDisabled}
					onChange={handleChange}
					style={{
						borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.3)',
						cursor: isDisabled ? 'not-allowed' : 'pointer',
					}}
					value={selected}
				>
					<option value="">- {placeholder} -</option>
					{options.map((option) => (
						<option key={`ha-${randNum()}`} value={option}>
							{option}
						</option>
					))}
				</select>
				<span
					style={{
						top: '1px',
						fontSize: '11px',
						color: error ? 'red' : '#000',
					}}
				>
					{placeholder}
				</span>
				{icon && <img src={icon} alt="" className="tsa-tf-icon" />}
			</div>
			{error && <div className="br-error">{error}</div>}
		</div>
	);
};

export default BRSelectField;
