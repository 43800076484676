import React from 'react';
import { MenuItem } from '.';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectIsMobileMenuOpen,
	toggleLoginState,
} from '../../redux-context/slices/appSlice';
import { storeInLocalStorage } from '../../utils/Functions';

const LeftMenu = () => {
	const dispatch = useDispatch();
	const isMenuOpen = useSelector(selectIsMobileMenuOpen);

	const logManagerOut = () => {
		dispatch(toggleLoginState());
		storeInLocalStorage('useWrite', false);
	};

	return (
		<aside
			className="left-menu-box flex"
			style={{
				left:
					window.screen.width < 999 && isMenuOpen
						? 0
						: window.screen.width < 999
						? '-350px'
						: 0,
				zIndex: window.screen.width < 999 && isMenuOpen ? 3 : 'unset',
			}}
		>
			<div>
				{/* Header with School logo and name */}
				<header className="ds-header">
					<div className="ds-logo">
						<img src={require('../../assets/mozano-crest.png')} alt="" />
					</div>
					<div className="ds-logo-name">Mozano Senior High School</div>
				</header>

				{/* Menu Links */}
				<section className="ds-menu-links">
					<MenuItem
						title={'Dashboard'}
						to={'/dashboard'}
						icon={require('../../assets/dashboard.png')}
						end={true}
					/>
					<MenuItem
						title={'Students'}
						to={'/dashboard/students'}
						icon={require('../../assets/students.png')}
					/>
					<MenuItem
						title={'Programmes'}
						to={'/dashboard/programmes'}
						icon={require('../../assets/programmes.png')}
					/>
					<MenuItem
						title={'Upload Details'}
						to={'/dashboard/upload-data'}
						icon={require('../../assets/cloud.png')}
					/>
					{/* <MenuItem
						title={'Settings'}
						to={'settings'}
						icon={require('../../assets/settings.png')}
					/> */}
				</section>
			</div>

			{/* Logout */}
			<footer className="btn-logout">
				<MenuItem
					title={'Logout'}
					to={'/auth'}
					icon={require('../../assets/logout.png')}
					onClick={logManagerOut}
				/>
			</footer>
		</aside>
	);
};

export default LeftMenu;
