import React from 'react';

export interface I_BRH1 {
	text: string;
}

const BRH1 = ({ text }: I_BRH1) => {
	return <h1 className="br-h1">{text}</h1>;
};

export default BRH1;
