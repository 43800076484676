import { useState, useEffect } from 'react';
import { Clue, HeaderDetail, StudentTable } from '.';
import { Spacer } from '../Auth';
import { useQuery, gql } from '@apollo/client';
import { NumbersOnly, TabTitle } from '../../utils/Functions';

const Students = () => {
	TabTitle('All Students - Brilliance App');

	const GET_ALL_STUDENTS = gql`
		query GetStudents($page: Int!, $limit: Int!, $filter: StudentFilter) {
			students(page: $page, limit: $limit, filter: $filter) {
				data {
					id
					passportPhoto
					fullName
					indexNumber
					enrolmentCode
					programme
					guardianContact
					gender
					programme
					hasPaid
					dateCreated
					dateModified
				}
				totalPages
			}
		}
	`;

	const [page, setPage] = useState(1);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [limit, setLimit] = useState(50);
	const [searchTerm, setSearchTerm] = useState('');

	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

	useEffect(() => {
		const timer = setTimeout(() => {
			setDebouncedSearchTerm(searchTerm);
		}, 400);

		return () => {
			clearTimeout(timer);
		};
	}, [searchTerm]);

	const { loading, error, data } = useQuery(GET_ALL_STUDENTS, {
		variables: {
			page,
			limit,
			filter: debouncedSearchTerm ? { fullName: debouncedSearchTerm } : {},
		},
	});
	const totalPages = data?.students?.totalPages;

	const handlePagination = (e: any) => {
		NumbersOnly(e);
		let val = e.target.value;
		val > 1 ? setPage(Number(e.target.value)) : setPage(1);
	};

	return (
		<div className="students-box centroid">
			<HeaderDetail text={'All Students'} />
			<Clue text={'Details of all students show here'} />
			<Spacer size={10} />

			{error && <p>Error : {error!.message}</p>}

			<input
				type="text"
				value={searchTerm}
				className="search-bar"
				placeholder="Search for student by name..."
				onChange={(e) => setSearchTerm(e.target.value)}
			/>

			<>
				<StudentTable
					size={limit}
					data={data?.students?.data}
					page={page}
					total={data?.students?.totalPages}
					isLoading={loading}
				/>

				{/* Pagination */}
				{totalPages > 1 && (
					<footer className="dash-pagination flex">
						<div
							className="btn-back"
							onClick={() => (page > 1 ? setPage(page - 1) : setPage(page))}
						/>
						<input
							type={window.screen.width < 999 ? 'number' : 'text'}
							value={page}
							min={1}
							minLength={1}
							onChange={handlePagination}
						/>
						<div
							className={`btn-next${
								page === data?.students?.totalPages ? ' no-events' : ''
							}`}
							onClick={() =>
								page < totalPages ? setPage(page + 1) : setPage(page)
							}
						/>
					</footer>
				)}
			</>
		</div>
	);
};

export default Students;
