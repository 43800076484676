import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Define a type for the slice state
interface AppState {
	isStudentInfoOpen: boolean;
	isStudentInfoExpanded: boolean;
	editId: string;
	hasLoggedIn: boolean;
	isMobileMenuOpen: boolean;
}

// Define the initial state using that type
const initialState: AppState = {
	isStudentInfoOpen: false,
	isStudentInfoExpanded: false,
	editId: '',
	hasLoggedIn: false,
	isMobileMenuOpen: false,
};

export const counterSlice = createSlice({
	name: 'app',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		toggleStudentInfoOpen: (state) => {
			state.isStudentInfoOpen = !state.isStudentInfoOpen;
		},
		toggleStudentInfoExpanded: (state) => {
			state.isStudentInfoExpanded = !state.isStudentInfoExpanded;
		},

		updateEditID: (state, action: PayloadAction<string>) => {
			state.editId = action.payload;
		},

		toggleLoginState: (state) => {
			state.hasLoggedIn = !state.hasLoggedIn;
		},

		toggleMobileMenuOpen: (state) => {
			state.isMobileMenuOpen = !state.isMobileMenuOpen;
		},
	},
});

export const {
	toggleStudentInfoOpen,
	toggleStudentInfoExpanded,
	updateEditID,
	toggleLoginState,
	toggleMobileMenuOpen,
} = counterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectStudentInfoOpen = (state: RootState) =>
	state.app.isStudentInfoOpen;
export const selectStudentInfoExpanded = (state: RootState) =>
	state.app.isStudentInfoExpanded;
export const editId = (state: RootState) => state.app.editId;
export const selectHasLoggedIn = (state: RootState) => state.app.hasLoggedIn;
export const selectIsMobileMenuOpen = (state: RootState) =>
	state.app.isMobileMenuOpen;

export default counterSlice.reducer;
