import React, { useEffect, useState } from 'react';
import {
	AuthWrapper,
	BRButton,
	BRSelectField,
	BRTextField,
	Spacer,
} from '../Auth';
import { TabTitle, randNum, storeInLocalStorage } from '../../utils/Functions';
import { COURSES } from '../../utils/data';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectStudent,
	updateStudent,
} from '../../redux-context/slices/studentSlice';
import { useNavigate } from 'react-router-dom';
import { REGIONS } from '../../utils/GHANA';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
	getStorage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
	deleteObject,
} from 'firebase/storage';

import { firebaseApp } from '../../firebase';
import { processIntoUrl } from '../../utils/Functions';

const storage = getStorage(firebaseApp);

const GET_STUDENT_QUERY = gql`
	query StudentByIndex($indexNumber: String!) {
		studentByIndex(indexNumber: $indexNumber) {
			id
			fullName
			indexNumber
			programme
		}
	}
`;

const DecaForm = () => {
	TabTitle('Personal Information - Brilliance App');

	const passportId = `pass-${randNum()}`;
	const dispatch = useDispatch();
	const student = useSelector(selectStudent);
	const navigate = useNavigate();

	const [file, setFile] = useState<File | null>(null);
	const [imageUrl, setImageUrl] = useState<string | null>(null);
	const [imageSrc, setImageSrc] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isPending, setIsPending] = useState(false);
	const [uploadProgress, setUploadProgress] = useState<number>(0);
	const [picError, setPicError] = useState('');

	const NATIONALITIES_API_URL =
		'https://kineticzephyr.onrender.com/nations/all';
	const [nationalities, setNationalities] = useState<string[]>([]);

	useEffect(() => {
		fetch(NATIONALITIES_API_URL)
			.then((response) => response.json())
			.then((data) => {
				const nationalitiesSet = new Set<string>();
				data.nations.forEach((nation: any) => {
					if (nation.demonym) {
						nationalitiesSet.add(nation.demonym);
					}
				});
				const nationalitiesArr = Array.from(nationalitiesSet).sort();
				setNationalities(nationalitiesArr);
			})
			.catch((error) => console.error(error));
	}, []);

	const { data: studentData } = useQuery(GET_STUDENT_QUERY, {
		variables: { indexNumber: student?.indexNumber },
	});

	/** Loads image for preview */
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = event.target.files?.[0];
		// console.log('selectedFile: ', selectedFile);

		if (selectedFile!.size > 3000000) {
			setPicError('File size exceeds 3MB limit');
			return;
		} else {
			setPicError('');
		}

		setFile(selectedFile!);

		if (selectedFile) {
			const reader = new FileReader();
			reader.onload = async (e: any) => {
				if (e.target?.result) {
					// set preview for image
					setImageSrc(e.target.result);

					if (imageUrl && imageUrl!.length > 2) handleDelete(imageUrl!);

					// upload image to firebase
					const picName = `${student.indexNumber.toString()}-${processIntoUrl(
						student.fullName
					)}`;
					const fileRef = ref(storage, picName);
					const uploadTask = uploadBytesResumable(fileRef, selectedFile);
					uploadTask.on(
						'state_changed',
						(snapshot) => {
							const progress =
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
							setUploadProgress(progress);
						},
						(error) => {
							console.error(error);
						},
						async () => {
							const downloadUrl = await getDownloadURL(fileRef);
							setImageUrl(downloadUrl);
							dispatch(updateStudent({ passportPhoto: downloadUrl }));

							setUploadProgress(0);
						}
					);
				}
			};
			reader.readAsDataURL(selectedFile);
		}
	};

	const handleDelete = async (url: string) => {
		if (url) {
			const fileRef = ref(storage, url);
			await deleteObject(fileRef);
			setImageUrl(null);
		}
	};

	const longCheck =
		file &&
		student.nationality &&
		student.class &&
		student.dateOfBirth &&
		student.placeOfBirth &&
		student.birthRegion;

	const UPDATE_STUDENT = gql`
		mutation UpdateStudent(
			$indexNumber: String!
			$passportPhoto: String!
			$nationality: String!
			$class: String!
			$dateOfBirth: String!
			$placeOfBirth: String!
			$birthRegion: String!
		) {
			editStudent(
				indexNumber: $indexNumber
				passportPhoto: $passportPhoto
				nationality: $nationality
				class: $class
				dateOfBirth: $dateOfBirth
				placeOfBirth: $placeOfBirth
				birthRegion: $birthRegion
			) {
				id
				indexNumber
				fullName
				programme
				passportPhoto
			}
		}
	`;

	/** Handle Form Button Click */
	const handleSubmit = () => {
		setIsSubmitting(true);
		setIsPending(true);

		setTimeout(() => {
			setIsPending(false);
		}, 500);

		if (!file || !imageUrl) {
			setPicError('Upload your passport picture');
			return;
		}

		if (!longCheck) {
			return;
		}

		editStudent({
			variables: {
				indexNumber: student.indexNumber.toString(),
				passportPhoto: student.passportPhoto,
				// gender: student.gender,
				nationality: student.nationality,
				// programme: student.programme,
				class: student.class,
				// residentialStatus: student.residentialStatus,
				dateOfBirth: student.dateOfBirth,
				placeOfBirth: student.placeOfBirth,
				birthRegion: student.birthRegion,
			},
		})
			.then((res) => {
				setIsPending(false);
				storeInLocalStorage('student', JSON.stringify(student));
				navigate('undeca');
			})
			.catch((err) => {
				console.log('ERR: ', err);
				setIsPending(false);
			});
	};

	const [editStudent] = useMutation(UPDATE_STUDENT);

	return (
		<AuthWrapper>
			<div className="auth-paper">
				<h2 className="bri-h2">Personal Information</h2>

				<section className="form-content">
					{/* Passport Size Pic */}
					<div className="left-form-pic flex">
						{uploadProgress > 0 && (
							<progress value={uploadProgress} max={100} />
						)}
						<aside className="passport-photo">
							<label htmlFor={passportId}>
								<input
									type="file"
									className="hidebx"
									id={passportId}
									accept="image/png, image/jpeg"
									onChange={handleFileChange}
								/>
								{file && <img src={imageSrc} alt="" />}
								<div className="pic-instruct">
									<div>
										Click to upload <br /> pasport size picture
									</div>
								</div>
							</label>
						</aside>
						{((!file && isSubmitting) || picError.length > 0) && (
							<div className="br-error">{picError}</div>
						)}
					</div>

					{/* Details */}
					<div className="form-personal-details ">
						{/* <BRSelectField
							placeholder={'Gender'}
							options={['Male', 'Female']}
							onChange={(e: any) =>
								dispatch(updateStudent({ gender: e.target.value }))
							}
							error={
								isSubmitting && student.gender === 'null'
									? 'Field is required'
									: ''
							}
						/> */}

						<BRSelectField
							placeholder={'Nationality'}
							options={nationalities}
							onChange={(e: any) =>
								dispatch(updateStudent({ nationality: e.target.value }))
							}
							error={
								isSubmitting && student.nationality === ''
									? 'Field is required'
									: ''
							}
						/>

						{/* <BRSelectField
							placeholder={'Programme'}
							options={COURSES.map((value) => value.name)}
							onChange={(e: any) =>
								dispatch(updateStudent({ programme: e.target.value }))
							}
							error={
								isSubmitting && student.programme === ''
									? 'Field is required'
									: ''
							}
						/> */}

						<BRTextField
							type={'text'}
							placeholder={'Programme Given'}
							onChange={() => {}}
							value={studentData?.studentByIndex?.programme}
							isDisabled={true}
							error={
								isSubmitting && student.dateOfBirth === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRSelectField
							placeholder={'Choose Class'}
							// isDisabled={studentData.programme === '' ? true : false}
							options={
								studentData?.studentByIndex?.programme
									? COURSES.filter(
											(value) =>
												value.name === studentData?.studentByIndex?.programme
									  )[0].classes.map((c) => c.name)
									: ['']
							}
							onChange={(e: any) =>
								dispatch(updateStudent({ class: e.target.value }))
							}
							error={
								isSubmitting && student.class === '' && isSubmitting
									? 'Field is required'
									: ''
							}
						/>

						{/* <BRSelectField
							placeholder={'Residential Status'}
							options={['DAY', 'BOARDING']}
							onChange={(e: any) =>
								dispatch(updateStudent({ residentialStatus: e.target.value }))
							}
							error={
								student.residentialStatus === '' && isSubmitting
									? 'Field is required'
									: ''
							}
						/> */}

						<BRTextField
							type={'date'}
							placeholder={'Date of Birth'}
							onChange={(e: any) =>
								dispatch(updateStudent({ dateOfBirth: e.target.value }))
							}
							error={
								isSubmitting && student.dateOfBirth === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRTextField
							type={'text'}
							placeholder={'Place of Birth'}
							onChange={(e: any) =>
								dispatch(updateStudent({ placeOfBirth: e.target.value }))
							}
							error={
								isSubmitting && student.placeOfBirth === ''
									? 'Field is required'
									: ''
							}
						/>

						<BRSelectField
							placeholder={'Region of Birth'}
							options={REGIONS.map((region) => region.region)}
							onChange={(e: any) =>
								dispatch(updateStudent({ birthRegion: e.target.value }))
							}
							error={
								isSubmitting && student.birthRegion === ''
									? 'Field is required'
									: ''
							}
						/>
					</div>

					<div style={{ clear: 'both' }}></div>
					<Spacer />

					<div className="form-btn-box">
						<BRButton
							text={'Next'}
							onClick={handleSubmit}
							isProcessing={isPending}
						/>
					</div>

					<Spacer size={20} />
				</section>
			</div>
		</AuthWrapper>
	);
};

export default DecaForm;
