// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyBSorOOZpttJ_RrJPpc4lEYt1nZraRs2FE',
	authDomain: 'mosecadmission.firebaseapp.com',
	projectId: 'mosecadmission',
	storageBucket: 'mosecadmission.appspot.com',
	messagingSenderId: '389612652641',
	appId: '1:389612652641:web:b7f9ec3da43a2c81c14220',
	measurementId: 'G-LLZSXQ9HMX',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Get Firestore instance
const firestore = getFirestore(firebaseApp);

// Get Storage instance
const storage = getStorage(firebaseApp);

const analytics = getAnalytics(firebaseApp);

export { firebaseApp, firestore, storage, analytics };
