import { gql } from '@apollo/client';

export const GET_ALL_STUDENTS = gql`
	query GetAllStudents {
		students(page: 4, limit: 2) {
			id
			fullName
			indexNumber
			enrolmentCode
			programme
			guardianContact
			gender
			programme
			dateCreated
			dateModified
		}
	}
`;

export const GET_ANALYTICS = gql`
	query GetAnalytics {
		analytics {
			totalStudents
			hasPaidSum
			scienceTotal
			generalArtsTotal
			agricTotal
			homeEconsCount
			visualArtsTotal
			businessTotal
			house1
			house2
			house3
			house4
			day
			boarding
		}
	}
`;
