import React, { useEffect, useState } from 'react';
import { BRButton, BRTextField } from '.';
import Spacer from './Spacer';
import {
	IsValidEmail,
	TabTitle,
	readFromLocalStorage,
	storeInLocalStorage,
} from '../../utils/Functions';
import { useNavigate } from 'react-router-dom';

/**
 * Login Screen for Admin
 * @returns Admin Login
 */
const AuthLogin = () => {
	const navigate = useNavigate();
	TabTitle('Admin Login - Brilliance App');

	const hasLoggedIn = JSON.parse(readFromLocalStorage('useWrite')!);
	useEffect(() => {
		if (hasLoggedIn) navigate('/dashboard');
		return;
	}, [hasLoggedIn, navigate]);

	const validCredentials = {
		email: 'mosecict@gmail.com',
		password: 'Manager12Password',
	};

	const [loginDetails, setLoginDetails] = useState({
		email: '',
		password: '',
	});

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isPending, setIsPending] = useState(false);

	const longCheck =
		validCredentials.email === loginDetails.email &&
		validCredentials.password === loginDetails.password;

	// const handleLogin = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
	// const handleLogin = (e: any) => {
	const handleLogin = () => {
		// e.preventDefault();
		setIsSubmitting(true);
		setIsPending(true);

		if (!longCheck) {
			setTimeout(() => {
				setIsPending(false);
			}, 1000);
			return;
		}

		setTimeout(() => {
			storeInLocalStorage('useWrite', true);
			navigate('/dashboard');
		}, 2500);
	};

	return (
		<div className="auth-box flex">
			<aside className="auth-paper">
				<img
					className="auth-app-logo"
					src={require('../../assets/brilliance-app.png')}
					alt=""
					title="Brilliance App"
				/>

				<h2 className="bri-h2">Admin Sign In</h2>

				<Spacer size={10} />
				<form>
					<div className="auth-form flex">
						<BRTextField
							type={'email'}
							placeholder={'Email Address'}
							onChange={(e) =>
								setLoginDetails({ ...loginDetails, email: e.target.value })
							}
							error={
								isSubmitting &&
								loginDetails.email.length > 0 &&
								!IsValidEmail(loginDetails.email)
									? 'Provide a valid email address'
									: ''
							}
						/>

						<BRTextField
							type={'password'}
							placeholder={'Password'}
							onChange={(e) =>
								setLoginDetails({ ...loginDetails, password: e.target.value })
							}
							error={
								isSubmitting && loginDetails.password === ''
									? 'Password not valid'
									: ''
							}
						/>

						<Spacer size={5} />

						<BRButton
							text={'Submit Login'}
							onClick={handleLogin}
							isProcessing={isPending}
						/>
					</div>
				</form>
			</aside>
		</div>
	);
};

export default AuthLogin;
