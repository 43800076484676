import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { Student } from '../../types/Student';

// Define a type for the slice state
interface StudentState {
	student: Student;
}

// Define the initial state using that type
const initialState: StudentState = {
	student: {
		indexNumber: '',
		enrolmentCode: '',
		fullName: '',
		momoNumber: '',
		hasPaid: false,
		passportPhoto: '',
		programme: '',
		class: '',
		aggregate: 0,
		residentialStatus: '',
		dateOfBirth: '',
		gender: 'null',
		placeOfBirth: '',
		birthRegion: '',
		hometown: '',
		hometownRegion: '',
		permanentAddress: '',
		nationality: '',
		religiousDenomination: '',
		JHSattended: '',
		JHSdistrict: '',
		JHStown: '',
		JHSregion: '',
		nameOfFather: '',
		fatherOccupation: '',
		fathersContact: '',
		fathersEmail: '',
		nameOfMother: '',
		mothersOccupation: '',
		mothersContact: '',
		mothersEmail: '',
		nameOfGuardian: '',
		guardiansEmail: '',
		postalAddressOfGuardian: '',
		relationshipToApplicant: '',
		guardianContact: '',
		residentialAddressOfGuardian: '',
		otherTelephoneContact: '',
		interestOfApplicant: [],
		schoolAwards: '',
		positionHeldByApplicant: '',
		acceptedAdmission: false,
		house: '',
		dateCreated: '',
		dateModified: '',
		modifiedBy: '',
	},
};

export const studentSlice = createSlice({
	name: 'student',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		updateStudent: (state, action: PayloadAction<Partial<Student>>) => {
			state.student = { ...state.student, ...action.payload };
		},
	},
});

export const { updateStudent } = studentSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectStudent = (state: RootState) => state.student.student;

export default studentSlice.reducer;
