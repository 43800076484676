import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import '../css/StudentForm.css';
import {
	DecaForm,
	UnDecaForm,
	DoDecaFrom,
	TriskaiDecaForm,
	FormSuccess,
} from '../components/StudentForm';
import { useSelector } from 'react-redux';
import { selectStudent } from '../redux-context/slices/studentSlice';

const StudentFormPage = () => {
	const navigate = useNavigate();
	const student = useSelector(selectStudent);

	useEffect(() => {
		if (student?.indexNumber === '') {
			navigate('/');
		}
	}, [navigate, student]);

	return (
		<Routes>
			<Route path="*" index element={<DecaForm />} />
			<Route path="undeca" element={<UnDecaForm />} />
			<Route path="dodeca" element={<DoDecaFrom />} />
			<Route path="triskaideca" element={<TriskaiDecaForm />} />
			<Route path="admission-success" element={<FormSuccess />} />
		</Routes>
	);
};

export default StudentFormPage;
