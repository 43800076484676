import React, { useEffect, useState } from 'react';
import { Clue, HeaderDetail, StudentTable } from '.';
import { Spacer } from '../Auth';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectStudentInfoExpanded,
	selectStudentInfoOpen,
	toggleStudentInfoExpanded,
	toggleStudentInfoOpen,
	updateEditID,
} from '../../redux-context/slices/appSlice';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { NumbersOnly, TabTitle } from '../../utils/Functions';

const ProgrammeDetails = () => {
	const dispatch = useDispatch();
	const studentInfoOpen = useSelector(selectStudentInfoOpen);
	const studentInfoExpanded = useSelector(selectStudentInfoExpanded);

	useEffect(() => {
		const handleKeydown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				// Trigger event logic here
				if (studentInfoOpen) dispatch(toggleStudentInfoOpen());
				if (studentInfoExpanded) dispatch(toggleStudentInfoExpanded());
				if (studentInfoOpen) dispatch(updateEditID(''));
			}
		};

		document.addEventListener('keydown', handleKeydown);

		return () => {
			document.removeEventListener('keydown', handleKeydown);
		};
	}, [dispatch, studentInfoExpanded, studentInfoOpen]);

	// Integrations
	const { course } = useParams();
	const [page, setPage] = useState(1);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [limit, setLimit] = useState(20);

	const urlToTitle = (name: string): string => {
		return name
			.split('-')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	const urlToCaps = (course: string): string => {
		const courseArr = course.split('-');
		const transformedArr = courseArr.map(
			(word) => word.charAt(0).toUpperCase() + word.slice(1)
		);
		return transformedArr.join(' ').toUpperCase();
	};

	const GET_PROGRAMME_STUDENTS = gql`
		query GetProgrammeStudents(
			$page: Int!
			$limit: Int!
			$programme: String!
			$filter: StudentFilter
		) {
			programme(
				page: $page
				limit: $limit
				programme: $programme
				filter: $filter
			) {
				data {
					id
					passportPhoto
					fullName
					indexNumber
					enrolmentCode
					programme
					guardianContact
					gender
					programme
					class
					hasPaid
					dateCreated
					dateModified
				}
				totalPages
			}
		}
	`;

	const courseCaps = urlToCaps(course!);
	const [searchTerm, setSearchTerm] = useState('');

	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

	useEffect(() => {
		const timer = setTimeout(() => {
			setDebouncedSearchTerm(searchTerm);
		}, 400);

		return () => {
			clearTimeout(timer);
		};
	}, [searchTerm]);

	// Usage:
	const { data, loading, error } = useQuery(GET_PROGRAMME_STUDENTS, {
		variables: {
			page: page,
			limit: limit,
			programme: courseCaps,
			filter: debouncedSearchTerm ? { fullName: debouncedSearchTerm } : {},
		},
	});

	console.log('DATA: ', data);

	const handlePagination = (e: any) => {
		NumbersOnly(e);
		let val = e.target.value;
		val > 1 ? setPage(Number(e.target.value)) : setPage(1);
	};
	const totalPages = data?.programme?.totalPages;
	TabTitle(`${urlToTitle(course!)} - Brilliance App`);

	return (
		<section className="prog-details centroid">
			<HeaderDetail text={course ? urlToTitle(course) : ''} />
			<Clue text={'Details of programme'} />
			<Spacer size={10} />

			{error && <p>Error : {error!.message}</p>}

			<input
				type="text"
				value={searchTerm}
				className="search-bar"
				placeholder="Search for student by name..."
				onChange={(e) => setSearchTerm(e.target.value)}
			/>

			<>
				<StudentTable
					size={limit}
					showClass
					data={data?.programme?.data}
					page={page}
					total={data?.programme?.totalPages}
					isLoading={loading}
				/>

				{/* Pagination */}
				{totalPages > 1 && (
					<footer className="dash-pagination flex">
						<div
							className="btn-back"
							onClick={() => (page > 1 ? setPage(page - 1) : setPage(page))}
						/>
						<input
							type={window.screen.width < 999 ? 'number' : 'text'}
							value={page}
							min={1}
							minLength={1}
							onChange={handlePagination}
						/>
						<div
							className={`btn-next${
								page === data?.programme?.totalPages ? ' no-events' : ''
							}`}
							onClick={() =>
								page < totalPages ? setPage(page + 1) : setPage(page)
							}
						/>
					</footer>
				)}
			</>
		</section>
	);
};

export default ProgrammeDetails;
