/**
 * Store data in local storage
 * @params key - STRING to read the stored data
 * @params value - STRING/NUMBER value to be stored in storage
 */
export const storeInLocalStorage = (key: string, value: any) => {
	if (typeof value === 'object') {
		localStorage.setItem(key, JSON.stringify(value));
	}
	localStorage.setItem(key, value);
	return true;
};

/**
 * Read data from local storage
 * @params key - STRING to read the stored data
 */
export const readFromLocalStorage = (key: string) => {
	return localStorage.getItem(key);
};

/**
 * Function scrolls to the top always
 * @returns Scroll to top
 */
export const ALWAYS_SCROLL_TO_TOP = () => {
	return window.scroll(0, 0);
};

/**
 * Converts string into url check example
 * @param phrase the text to be converted into url string
 * @example processIntoUrl('Fractal Audio')
 * @returns URL string with hyphes: fractal-audio
 */
export const processIntoUrl = (phrase: string) => {
	return phrase
		.replace(/\s+/g, '-')
		.replace(/\/+/g, '')
		.replace(/-+/g, '-')
		.toLowerCase();
};

/**
 * Generates random number between 1 - 10,000
 * @returns Radom Number
 */
export const randNum = () => {
	return Math.floor(Math.random() * 10000);
};

/**
 * Sets the Tab's title
 * @param title - title to be renamed to
 * @returns Document Title
 */
export const TabTitle = (title: string) => {
	return (document.title = title);
};

/**
 * Allow only numbers to be entered
 * @param event
 */
export const NumbersOnly = (event: any) => {
	let charCode = event.which ? event.which : event.keyCode;
	if (
		charCode > 31 &&
		(charCode < 48 || charCode > 57) &&
		(charCode >= 96 || charCode <= 105) &&
		!(event.ctrlKey || event.metaKey)
	) {
		event.preventDefault();
	}  
};

/**
 * Tells whether or not a string is an email
 * @param email - mail to be validated
 * @returns Email Boolean Validator
 */
export const IsValidEmail = (email: string) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

/**
 * Readable date string
 * @param dateStr - date string to be converted to date and time
 * @returns Date with day, month, year and time in AM/PM
 */
export const FormatLongDate = (dateStr: string) => {
	// const dateStr = 'Mon Feb 13 2023 04:18:54 GMT+0000 (Greenwich Mean Time)';
	const date = new Date(dateStr);

	const day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date);
	const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
	const year = date.getFullYear();
	let hour = date.getHours();
	const ampm = hour >= 12 ? 'PM' : 'AM';
	hour = hour % 12 || 12;
	const minute = date.getMinutes();
	const formattedDate = `${day}${getDaySuffix(
		Number(day)
	)} ${month}, ${year} at ${hour}:${minute
		.toString()
		.padStart(2, '0')} ${ampm}`;

	function getDaySuffix(day: number) {
		if (day >= 11 && day <= 13) {
			return 'th';
		}
		switch (day % 10) {
			case 1:
				return 'st';
			case 2:
				return 'nd';
			case 3:
				return 'rd';
			default:
				return 'th';
		}
	}

	return formattedDate;
};
