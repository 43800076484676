import React from 'react';
import { NavLink } from 'react-router-dom';

interface IMenuItem {
	title: string;
	to?: string;
	icon: string;
	end?: boolean;
	onClick?: () => void;
}

const MenuItem = ({
	title,
	to = '',
	icon,
	end = false,
	onClick,
}: IMenuItem) => {
	const activeClassName = 'menu-item flex menu-item-active';
	return (
		<NavLink
			to={to}
			className={({ isActive }) =>
				isActive ? activeClassName : 'menu-item flex'
			}
			end={end}
			onClick={onClick}
		>
			{icon && <img src={icon} alt="" />}
			<span>{title}</span>
		</NavLink>
	);
};

export default MenuItem;
