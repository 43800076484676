import React from 'react';

interface IBRButton {
	text: string;
	onClick: () => void;
	isProcessing?: boolean;
	className?: string;
}

const BRButton = ({
	text,
	onClick,
	isProcessing,
	className = '',
}: IBRButton) => {
	return (
		<div className={`br-btn ${className}`} onClick={onClick}>
			{!isProcessing ? <span>{text}</span> : <div className="btn-spin"></div>}
		</div>
	);
};

export default BRButton;
