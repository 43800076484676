import Spacer from './Spacer';
import { TabTitle } from '../../utils/Functions';
import { PayStackBtn } from '.';

const PayUI = () => {
	TabTitle('Payment Confirmation • Brilliance App');

	return (
		<section className="auth-box flex">
			<div className="br-pay-ui auth-paper">
				<img
					className="auth-app-logo"
					src={require('../../assets/pay-money.png')}
					alt=""
					title="Brilliance App"
				/>

				<h2 className="bri-h2">Registration Payment</h2>

				<div className="br-pay-content flex">
					<div className="br-pay-amount">GHS 50</div>
					<div className="br-pay-subtext">
						To proceed with your registration, please make the payment for the
						amount stated above. You can pay through any mobile money provider
						such as <br /> <b>MTN, Vodafone, Airtel, MasterCard or Visa</b>.
						<br />
						<br />
						<PayStackBtn />
						<br />
					</div>

					<Spacer />
				</div>
			</div>
		</section>
	);
};

export default PayUI;
