import React from 'react';

interface IInfoTab {
	label: string;
	value: string | number | string[];
}

const InfoTab = ({ label, value }: IInfoTab) => {
	return (
		<aside
			className="info-tab flex"
			style={{
				flexDirection: 'column',
				gap: '3px',
				fontSize: '12px',
				fontFamily: 'Open Sans, sans-serif',
			}}
		>
			<div
				className="label"
				style={{
					fontSize: '10px',
					textTransform: 'uppercase',
					// opacity: 0.5,
				}}
			>
				{label}
			</div>
			<div className="value">{value !== null ? value : '-'}</div>
		</aside>
	);
};

export default InfoTab;
